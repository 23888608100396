<template>
    <div class="padding-container">
        <notifications group="foo" position="top right" />
        <div class="box">
            <div class="mb-3">
                <h3 class="fw-bold">Daftar Lost Order</h3>
            </div>
            <div class="d-flex align-items-center no-flex mt-4">
                <div class="me-4 m-mx-0 m-mt-2">
                    <div class="position-relative">
                        <div class="input-group">
                            <input
                                type="text"
                                class="form-control form-control-lg pl-search w-search border-search"
                                placeholder="Cari nama customer"
                                v-model="search"
                            />
                            <button
                                class="btn btn-primary px-4"
                                type="button"
                                @click="searchData()"
                            >
                                Cari
                            </button>
                        </div>
                        <span class="search-icon left">
                            <i class="pi pi-search"></i>
                        </span>
                    </div>
                </div>
                <div class="m-mx-0 m-mt-2 me-3">
                    <button
                        class="btn btn-outline-yellow btn-filter px-3"
                        @click="isFilter = !isFilter"
                    >
                        <img
                            src="../assets/dashboard/filter.png"
                            class="me-1"
                            width="18"
                            alt=""
                        />
                        Filter
                    </button>
                </div>
            </div>
            <div class="box mt-3 position-relative" v-if="isFilter">
                <div class="row">
                    <div class="col-md-8 mb-2" v-show="isRole(managerRoles)">
                        <label class="fw-bold mb-1">CRM Specialist</label>
                        <multiselect
                            v-model="specialist"
                            :options="specialists"
                            label="name"
                            track-by="id"
                            placeholder="Pilih CRM specialist"
                            class="multi-form-custom"
                            :show-labels="false"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-4 mt-filter">
                        <button
                            class="btn btn-outline-yellow px-5 me-3"
                            @click="clearFilter()"
                            :disabled="category == null && specialist == null"
                        >
                            Clear
                        </button>
                        <button
                            class="btn btn-green px-5"
                            :disabled="category == null && specialist == null"
                            @click="filterDate()"
                        >
                            Filter
                        </button>
                    </div>
                </div>
            </div>
            <div class="d-flex align-items-center mt-4">
                <div>
                    Menampilkan
                    <span class="fw-bold">1-{{ limit }}</span> Data dari
                    <span class="fw-bold">{{ list.total_data }}</span>
                    Data
                </div>
                <div class="ms-auto">
                    <div class="d-flex align-items-center">
                        <div class="me-2">Baris per halaman</div>
                        <select
                            class="form-control form-select w-select"
                            v-model="limit"
                            @change="getLists()"
                        >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="is_load_list">
                <ListLoader />
            </div>
            <div v-else>
                <div class="table-responsive-custom">
                    <table class="table table-bordered mt-4">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">No</th>
                                <th class="text-center" scope="col">
                                    <div class="d-flex align-items-center">
                                        Tanggal Deal Order

                                        <div class="ms-auto">
                                            <a
                                                href="javascript:void(0)"
                                                class="dropdown-toggle ms-2"
                                                id="statusRefund"
                                                data-bs-toggle="dropdown"
                                                data-bs-auto-close="true"
                                                aria-expanded="false"
                                            >
                                                <img
                                                    src="../assets/dashboard/sort.png"
                                                    width="9"
                                                    alt=""
                                                />
                                            </a>
                                            <ul
                                                class="dropdown-menu"
                                                aria-labelledby="statusRefund"
                                            >
                                                <li>
                                                    <a
                                                        class="dropdown-item"
                                                        href="javascript:void(0)"
                                                        @click="sortBy('asc')"
                                                    >
                                                        Terbaru
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        class="dropdown-item"
                                                        href="javascript:void(0)"
                                                        @click="sortBy('desc')"
                                                    >
                                                        Terlama
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </th>
                                <th class="text-center" scope="col">
                                    Nama Customer
                                </th>
                                <th class="text-center" scope="col">
                                    No. Handphone Customer
                                </th>
                                <th class="text-center" scope="col">
                                    Kategori
                                </th>
                                <th
                                    class="text-center"
                                    scope="col"
                                    v-show="isRole(managerRoles)"
                                >
                                    CRM Specialist
                                </th>
                                <th class="text-center" scope="col">
                                    Keterangan
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(item, index) in list.list_data"
                                :key="index"
                            >
                                <td class="text-center">{{ index + 1 }}</td>
                                <td class="text-center">
                                    {{ item.created_at }}
                                </td>
                                <td class="capitalize">
                                    <a
                                        :href="
                                            `/data/leads/detail?lead_id=` +
                                            item.lead_id
                                        "
                                        class="text-link"
                                    >
                                        {{ item.customer_name }}
                                    </a>
                                </td>
                                <td class="text-center">+{{ item.phone }}</td>
                                <td>
                                    <span
                                        class="tag mb-0 pb-0 capitalize"
                                        v-bind:class="{
                                            'blue-cro':
                                                item.category == 'new_order',
                                            'green-cro':
                                                item.category == 'repeat_order',
                                        }"
                                    >
                                        {{ item.category.split("_").join(" ") }}
                                    </span>
                                </td>
                                <td
                                    class="capitalize"
                                    v-show="isRole(managerRoles)"
                                >
                                    {{ item.specialist }}
                                </td>
                                <td>
                                    {{ item.description }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-if="list.list_data.length == 0" class="text-center mt-5">
                    <img src="../assets/no-data-green.png" width="100" alt="" />
                    <div class="mt-4 mb-4 text-gray">
                        Belum ada daftar Lost Order
                    </div>
                </div>
            </div>
            <div class="d-flex mt-4">
                <div class="ms-auto">
                    <div class="d-flex align-items-center">
                        <b-pagination-nav
                            v-if="list.list_data.length > 0"
                            v-model="currentPage"
                            :number-of-pages="totalPage"
                            base-url="#"
                            first-number
                            align="right"
                            @input="changePage()"
                        ></b-pagination-nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Api from "../api/Api";
import ListLoader from "../components/ListLoader.vue";
import moment from "moment";
import Multiselect from "vue-multiselect";

export default {
    components: {
        Multiselect,
        ListLoader,
    },
    name: "LostOrder",
    props: ["roles"],
    data() {
        return {
            moment: moment,
            search: "",
            isFilter: false,
            specialist: null,
            specialists: [],
            category: null,
            categories: [
                {
                    name: "New Order",
                    id: "new",
                },
                {
                    name: "Repeat Order",
                    id: "repeat",
                },
            ],
            managerRoles: ["crm-manager"],
            list: {
                list_data: [],
                total_data: 0,
            },
            currentPage: 0,
            totalPage: 10,
            limit: 10,
            is_load_list: false,
            sort_at: "asc",
        };
    },
    created() {
        this.getSpecialists();
        this.getLists();
    },
    methods: {
        filterDate() {
            this.getLists();
        },
        clearFilter() {
            this.specialist = null;
            this.category = null;
            this.getLists();
        },
        getLists() {
            let payload = {
                search: this.search,
                limit: this.limit,
                page:
                    this.currentPage == 0 || this.currentPage == null
                        ? 0
                        : this.currentPage - 0,
            };
            if (this.specialist) {
                payload.specialist_id = this.specialist?.id;
            }
            if (this.sort_at) {
                payload.sort_at = this.sort_at;
            }
            if (this.category) {
                payload.category = this.category?.id;
            }
            this.is_load_list = true;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/result/lost/lists`, {
                params: payload,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.list = res.data.data;
                    this.totalPage = res.data.data.total_page;
                    this.is_load_list = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_load_list = false;
                });
        },
        isRole(value) {
            let result = value.some((el) => this.roles.includes(el));
            return result;
        },
        getSpecialists() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/user`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.specialists = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        sortBy(value) {
            this.sort_at = value;
            this.getLists();
        },
        changePage() {
            this.getLists();
        },
        searchData() {
            this.getLists();
            this.currentPage = 0;
        },
    },
};
</script>

<style scoped>
.w-search {
    width: 280px !important;
}
th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
.table-light {
    background-color: #f7f7f7 !important;
    --bs-table-border-color: #f7f7f7 !important;
    --bs-table-bg: #f7f7f7 !important;
}
.mt-filter {
    margin-top: 25px;
}
</style>
