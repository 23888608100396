<template>
    <div class="padding-container">
        <notifications group="foo" position="top right" />
        <div class="row">
            <div class="col-md-5">
                <div class="box">
                    <h5 class="fw-bold mb-0">Input Order ID</h5>
                    <div class="position-relative mt-3">
                        <div class="input-group">
                            <input
                                type="text"
                                class="form-control form-control-lg pl-search w-search"
                                placeholder="Masukkan order ID"
                                v-model="search"
                            />
                        </div>
                        <span class="search-icon left">
                            <i class="pi pi-search"></i>
                        </span>
                    </div>
                    <div class="d-flex mt-3">
                        <div class="ms-auto">
                            <button
                                class="btn btn-outline-yellow px-4 me-2"
                                :disabled="search == ''"
                                @click="clearSearch()"
                            >
                                Clear
                            </button>
                            <button
                                class="btn btn-primary px-5"
                                :disabled="search == '' || is_search"
                                @click="searchOrder()"
                            >
                                <span
                                    class="spinner-border spinner-border-sm me-2"
                                    v-if="is_search"
                                ></span>
                                Cari
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-7">
                <div class="box">
                    <div class="d-flex align-items-center">
                        <h5 class="fw-bold mb-0">Hasil Tracking</h5>
                        <div class="ms-auto" v-if="track.tracks.length > 0">
                            <a
                                href="javascript:void(0)"
                                @click="showModalEditManifest(track)"
                                class="text-primary d-flex align-items-center"
                            >
                                <img
                                    src="../assets/clock.png"
                                    width="13"
                                    class="me-1"
                                    alt=""
                                />
                                Update
                            </a>
                        </div>
                    </div>
                    <div class="mt-4">
                        <div class="experience" v-if="track.tracks.length > 0">
                            <div
                                class="item"
                                v-bind:class="{
                                    active: index == 0,
                                }"
                                v-for="(track, index) in track.tracks"
                                :key="index"
                            >
                                <div
                                    class="fw-semibold"
                                    v-bind:class="{
                                        'text-green': index == 0,
                                    }"
                                >
                                    {{ track.title }}
                                </div>
                                <div class="job-info">
                                    <div class="size-12 text-gray-dashboard">
                                        {{
                                            moment(track.created_at).format(
                                                "DD MMM"
                                            )
                                        }}
                                    </div>
                                    <div class="size-12 text-gray-dashboard">
                                        {{
                                            moment(track.created_at).format(
                                                "HH:mm"
                                            )
                                        }}
                                    </div>
                                </div>
                                <div class="text-gray-dashboard size-12">
                                    {{ track.description }}
                                </div>
                            </div>
                        </div>
                        <div class="text-center" v-else>
                            <img
                                src="../assets/tracking-empty.png"
                                width="130"
                                alt=""
                            />
                            <div class="mt-3 text-gray">
                                Input order ID terlebih dahulu
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalEditTracking"></div>
            <div class="modal-body-chat md" v-if="modalEditTracking">
                <div class="mt-3 mb-3">
                    <h4 class="mb-0 fw-bold">
                        Update Status Pengiriman - {{ dataEdit.receipt_code }}
                    </h4>
                    <div class="mt-3">
                        <label class="fw-bold mb-1">
                            Status Pengiriman Terkini
                        </label>
                        <div class="form-box text-dark">
                            <template
                                v-if="dataEdit.current_status == 'ontheway'"
                            >
                                Dalam Perjalanan
                            </template>
                            <template
                                v-if="dataEdit.current_status == 'transit'"
                            >
                                Transit
                            </template>
                            <template
                                v-if="dataEdit.current_status == 'success'"
                            >
                                Sampai
                            </template>
                        </div>
                        <div class="mt-3">
                            <label class="fw-bold mb-1">
                                Perubahan Status Pengiriman
                                <span class="text-danger">*</span>
                            </label>
                            <multiselect
                                v-model="status"
                                :options="statuses"
                                label="label"
                                track-by="id"
                                placeholder="Pilih status pengiriman"
                                class="multi-form-custom"
                                :show-labels="false"
                            >
                            </multiselect>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="
                                (modalEditTracking = false), (status = null)
                            "
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Batal
                        </a>
                    </div>
                    <div class="col-md-6 mt-4">
                        <button
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            :disabled="is_update_tracking || status == null"
                            @click="updateTracking()"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="is_update_tracking"
                            ></span>
                            Update
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">
                        Status Pengiriman Barang Berhasil Diupdate
                    </h4>
                </div>
                <div class="mt-4">
                    <a
                        href="javascript:void(0)"
                        @click="searchOrder(), (modalSuccess = false)"
                        class="btn btn-primary w-100 btn-lg"
                    >
                        Tutup
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../api/Api";
import moment from "moment";
import Multiselect from "vue-multiselect";

export default {
    components: {
        Multiselect,
    },
    name: "Tracking",
    data() {
        return {
            moment: moment,
            search: "",
            track: {
                current_status: "",
                receipt_code: "",
                tracks: [],
            },
            is_search: false,
            modalEditTracking: false,
            modalSuccess: false,
            dataEdit: null,
            is_update_tracking: false,
            status: null,
            statuses: [
                {
                    id: "ontheway",
                    label: "Dalam Perjalanan",
                },
                {
                    id: "transit",
                    label: "Transit",
                },
                {
                    id: "success",
                    label: "Sampai",
                },
            ],
        };
    },
    created() {},
    mounted() {},
    computed: {},
    methods: {
        searchOrder() {
            this.is_search = true;
            var data = {
                receipt_code: this.search,
            };
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/track/findorder`, {
                params: data,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.track = res.data.data;
                    this.is_search = false;
                })
                .catch((err) => {
                    this.is_search = false;
                    if (err.response.data.data == null) {
                        this.$toast.error(err.response.data.message, {
                            position: "top-right",
                            timeout: 2000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: false,
                            icon: true,
                            rtl: false,
                        });
                    } else {
                        this.$toast.error(err.response.data.data.message, {
                            position: "top-right",
                            timeout: 2000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: false,
                            icon: true,
                            rtl: false,
                        });
                    }
                });
        },
        clearSearch() {
            this.search = "";
            this.track.tracks = [];
        },
        showModalEditManifest(track) {
            this.dataEdit = track;
            this.modalEditTracking = true;
        },
        updateTracking() {
            this.is_update_tracking = true;
            var data = {
                receipt_code: this.dataEdit?.receipt_code,
                status: this.status?.id,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/track/order/update`,
                data,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    this.modalEditTracking = false;
                    this.is_update_tracking = false;
                    this.modalSuccess = true;
                })
                .catch((err) => {
                    this.modalEditTracking = false;
                    this.is_update_tracking = false;
                    if (err.response.data.data == null) {
                        this.$toast.error(err.response.data.message, {
                            position: "top-right",
                            timeout: 2000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: false,
                            icon: true,
                            rtl: false,
                        });
                    } else {
                        this.$toast.error(err.response.data.data.message, {
                            position: "top-right",
                            timeout: 2000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: false,
                            icon: true,
                            rtl: false,
                        });
                    }
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.experience {
    border-left: 3px dashed #d9f2e4;
    padding: 0 30px;
    margin-left: 60px;
}
.experience .item {
    position: relative;
    margin-bottom: 16px;
}
.experience .item::before {
    content: "";
    position: absolute;
    left: -39px;
    top: 0;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #d9f2e4;
}
.experience .item.active::before {
    background: #40bf76;
    border: 5px solid #d9f2e4;
    width: 20px;
    height: 20px;
    left: -41px;
}
.experience .item::after {
    content: "";
    position: absolute;
    left: -37px;
    top: 6px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
}
.experience .job-info {
    position: absolute;
    left: -92px;
    top: 0;
}
</style>
