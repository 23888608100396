<template>
    <h1 class="text-center p-5 fw-bold">Dashboard Head Driver</h1>
</template>

<script>
export default {
    components: {},
    name: "DashboardHeadDriver",
    data() {
        return {};
    },
    created() {},
    methods: {},
    mounted() {},
};
</script>

<style lang="scss" scoped></style>
