<template>
    <div class="padding-container">
        <div class="row justify-content-center mt-4">
            <div class="col-md-6">
                <div class="box p-0">
                    <ul class="nav nav-pills" id="pills-tab" role="tablist">
                        <li class="nav-item w-50" role="presentation">
                            <button
                                class="nav-link dashboard left w-100"
                                v-bind:class="{
                                    active: tab_type == 'process',
                                }"
                                @click="getTabValue('process')"
                            >
                                Dalam Proses ({{ list_process.total }})
                            </button>
                        </li>
                        <li class="nav-item w-50" role="presentation">
                            <button
                                class="nav-link dashboard right w-100"
                                v-bind:class="{
                                    active: tab_type == 'done',
                                }"
                                @click="getTabValue('done')"
                            >
                                Selesai ({{ list_done.total_pickup }})
                            </button>
                        </li>
                    </ul>
                    <div class="p-4">
                        <div class="tab-content" id="pills-tabContent">
                            <div
                                class="tab-pane fade"
                                v-bind:class="{
                                    'show active': tab_type == 'process',
                                }"
                            >
                                <template v-if="lists.length > 0">
                                    <div
                                        class="box mt-3 p-0"
                                        v-for="(item, index) in lists"
                                        :key="index"
                                    >
                                        <div class="header-card">
                                            <div
                                                class="d-flex align-items-center"
                                            >
                                                <div
                                                    class="fw-bold capitalize d-flex align-items-center"
                                                >
                                                    <img
                                                        src="../../assets/truck.png"
                                                        width="18"
                                                        class="me-1"
                                                        alt=""
                                                    />
                                                    <template
                                                        v-if="
                                                            item.type ==
                                                            'pickup'
                                                        "
                                                    >
                                                        Jemput Barang
                                                    </template>
                                                    <template v-else>
                                                        {{ item.type }}
                                                    </template>
                                                </div>
                                                <div class="ms-auto">
                                                    <small
                                                        class="tag radius mobile pb-0"
                                                        v-bind:class="{
                                                            'yellow-cro':
                                                                item.status ==
                                                                'Menunggu Dijemput',
                                                            'yellow-cro-thin':
                                                                item.status ==
                                                                'Sedang Dijemput',
                                                            'blue-cro':
                                                                item.status ==
                                                                'Selesai Dijemput',
                                                            'green-cro':
                                                                item.status ==
                                                                'Off Loading',
                                                        }"
                                                    >
                                                        {{ item.status }}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="p-4 pt-2 pb-2">
                                            <table
                                                class="w-100 ps-0 fw-bold"
                                                cellpadding="10"
                                            >
                                                <tr>
                                                    <td
                                                        class="ps-0 w-80 fw-normal"
                                                    >
                                                        <div class="d-flex">
                                                            <div class="me-1">
                                                                <img
                                                                    src="../../assets/location.png"
                                                                    width="16"
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div>
                                                                <div
                                                                    class="text-green fw-bold"
                                                                >
                                                                    {{
                                                                        item.regency
                                                                    }},
                                                                    {{
                                                                        item.province
                                                                    }}
                                                                </div>
                                                                <div
                                                                    class="text-primary"
                                                                >
                                                                    <u>
                                                                        {{
                                                                            item.pickup
                                                                        }}
                                                                    </u>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr class="border-top">
                                                    <td
                                                        class="ps-0 w-80 fw-normal"
                                                    >
                                                        <div
                                                            class="d-flex align-items-center ms-auto"
                                                        >
                                                            <div class="me-1">
                                                                <img
                                                                    src="../../assets/profile.png"
                                                                    width="16"
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div>
                                                                {{
                                                                    item.customer
                                                                }}
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr class="border-top">
                                                    <td
                                                        class="ps-0 w-80 fw-normal"
                                                    >
                                                        <div class="d-flex">
                                                            <div class="me-2">
                                                                <img
                                                                    src="../../assets/wa.png"
                                                                    width="16"
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div>
                                                                <a
                                                                    :href="
                                                                        `https://wa.me/` +
                                                                        item.phone
                                                                    "
                                                                    target="_blank"
                                                                    class="text-primary"
                                                                >
                                                                    <u>
                                                                        +{{
                                                                            item.phone
                                                                        }}
                                                                    </u>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr
                                                    class="border-top"
                                                    v-if="item.isShow"
                                                >
                                                    <td
                                                        class="ps-0 pe-0 w-80 fw-normal"
                                                    >
                                                        <div class="box note">
                                                            <div
                                                                class="d-flex align-items-center"
                                                            >
                                                                <div
                                                                    class="me-2"
                                                                >
                                                                    <img
                                                                        src="../../assets/note-yellow.png"
                                                                        width="30"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div>
                                                                    Notes dari
                                                                    CRM
                                                                    Specialist
                                                                    <div
                                                                        class="text-note fw-semibold"
                                                                    >
                                                                        {{
                                                                            item.note
                                                                        }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="fw-semibold mb-2 mt-2"
                                                        >
                                                            Informasi Barang
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-8">
                                                                <div
                                                                    class="d-flex"
                                                                >
                                                                    <div
                                                                        class="me-2"
                                                                    >
                                                                        <img
                                                                            src="../../assets/dimension.png"
                                                                            width="18"
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        {{
                                                                            item
                                                                                ?.dimension
                                                                                ?.width ??
                                                                            0
                                                                        }}cm X
                                                                        {{
                                                                            item
                                                                                ?.dimension
                                                                                ?.length ??
                                                                            0
                                                                        }}cm X
                                                                        {{
                                                                            item
                                                                                ?.dimension
                                                                                ?.height ??
                                                                            0
                                                                        }}cm
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    class="d-flex mt-2"
                                                                >
                                                                    <div
                                                                        class="me-2"
                                                                    >
                                                                        <img
                                                                            src="../../assets/notes.png"
                                                                            width="18"
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        {{
                                                                            item.description
                                                                        }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4">
                                                                <div
                                                                    class="d-flex"
                                                                >
                                                                    <div
                                                                        class="me-2"
                                                                    >
                                                                        <img
                                                                            src="../../assets/kg.png"
                                                                            width="18"
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        {{
                                                                            item.weight
                                                                        }}
                                                                        Kg
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                            <div
                                                class="mb-2"
                                                v-if="item.isShow"
                                            >
                                                <button
                                                    class="btn btn-green btn-search w-100"
                                                    v-if="
                                                        item.status ==
                                                        'Menunggu Dijemput'
                                                    "
                                                    @click="
                                                        openModalOntheway(item)
                                                    "
                                                >
                                                    Menuju Lokasi
                                                </button>
                                                <button
                                                    class="btn btn-primary btn-search w-100"
                                                    v-if="
                                                        item.status ==
                                                        'Sedang Dijemput'
                                                    "
                                                    @click="
                                                        openModalPickup(item)
                                                    "
                                                >
                                                    Jemput Barang
                                                </button>
                                            </div>
                                        </div>
                                        <div class="border-top">
                                            <div class="p-3 text-center">
                                                <a
                                                    href="javascript:void(0)"
                                                    @click="showContent(index)"
                                                    class="text-dark"
                                                >
                                                    <div
                                                        class="d-flex align-items-center justify-content-center"
                                                    >
                                                        <template
                                                            v-if="
                                                                item.isShow ==
                                                                true
                                                            "
                                                        >
                                                            <div>
                                                                Lihat Lebih
                                                                Sedikit
                                                            </div>
                                                            <i
                                                                class="fa fa-angle-up size-20 ms-1"
                                                            ></i>
                                                        </template>
                                                        <template
                                                            v-if="
                                                                item.isShow ==
                                                                false
                                                            "
                                                        >
                                                            <div>
                                                                Lihat Lebih
                                                                Banyak
                                                            </div>
                                                            <i
                                                                class="fa fa-angle-down size-20 ms-1"
                                                            ></i>
                                                        </template>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="text-center mt-3">
                                        <img
                                            src="../../assets/no-data-green.png"
                                            width="70"
                                            alt=""
                                        />
                                        <div class="mt-3 mb-4 text-gray">
                                            Tidak ada daftar orderan
                                        </div>
                                    </div>
                                </template>
                            </div>
                            <div
                                class="tab-pane fade"
                                v-bind:class="{
                                    'show active': tab_type == 'done',
                                }"
                            >
                                <div
                                    class="d-flex mt-2 mb-2"
                                    v-if="dones.length > 0"
                                >
                                    <div class="ms-auto">
                                        <button
                                            class="btn btn-primary px-3"
                                            :disabled="
                                                list_done.is_done == false
                                            "
                                            @click="
                                                modalConfirmationOffLoad = true
                                            "
                                        >
                                            Off Loading Barang
                                        </button>
                                    </div>
                                </div>
                                <template v-if="dones.length > 0">
                                    <div
                                        class="box mt-3 p-0"
                                        v-for="(item, index) in dones"
                                        :key="index"
                                    >
                                        <div class="header-card">
                                            <div
                                                class="d-flex align-items-center"
                                            >
                                                <div
                                                    class="fw-bold capitalize d-flex align-items-center"
                                                >
                                                    <img
                                                        src="../../assets/truck.png"
                                                        width="18"
                                                        class="me-1"
                                                        alt=""
                                                    />
                                                    <template
                                                        v-if="
                                                            item.type ==
                                                            'pickup'
                                                        "
                                                    >
                                                        Jemput Barang
                                                    </template>
                                                    <template v-else>
                                                        {{ item.type }}
                                                    </template>
                                                </div>
                                                <div class="ms-auto">
                                                    <small
                                                        class="tag radius mobile pb-0"
                                                        v-bind:class="{
                                                            'yellow-cro':
                                                                item.status ==
                                                                'Menunggu Dijemput',
                                                            'yellow-cro-thin':
                                                                item.status ==
                                                                'Sedang Dijemput',
                                                            'blue-cro':
                                                                item.status ==
                                                                'Selesai Dijemput',
                                                            'green-cro':
                                                                item.status ==
                                                                'Off Loading',
                                                        }"
                                                    >
                                                        {{ item.status }}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="p-4 pt-2 pb-2">
                                            <table
                                                class="w-100 ps-0 fw-bold"
                                                cellpadding="10"
                                            >
                                                <tr>
                                                    <td
                                                        class="ps-0 w-80 fw-normal"
                                                    >
                                                        <div class="d-flex">
                                                            <div class="me-1">
                                                                <img
                                                                    src="../../assets/location.png"
                                                                    width="16"
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div>
                                                                <div
                                                                    class="text-green fw-bold"
                                                                >
                                                                    {{
                                                                        item.regency
                                                                    }},
                                                                    {{
                                                                        item.province
                                                                    }}
                                                                </div>
                                                                <div
                                                                    class="text-primary"
                                                                >
                                                                    <u>
                                                                        {{
                                                                            item.pickup
                                                                        }}
                                                                    </u>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr class="border-top">
                                                    <td
                                                        class="ps-0 w-80 fw-normal"
                                                    >
                                                        <div
                                                            class="d-flex align-items-center ms-auto"
                                                        >
                                                            <div class="me-1">
                                                                <img
                                                                    src="../../assets/profile.png"
                                                                    width="16"
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div>
                                                                {{
                                                                    item.customer
                                                                }}
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr class="border-top">
                                                    <td
                                                        class="ps-0 w-80 fw-normal"
                                                    >
                                                        <div
                                                            class="d-flex align-items-center"
                                                        >
                                                            <div class="d-flex">
                                                                <div
                                                                    class="me-2"
                                                                >
                                                                    <img
                                                                        src="../../assets/wa.png"
                                                                        width="16"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <a
                                                                        :href="
                                                                            `https://wa.me/` +
                                                                            item.phone
                                                                        "
                                                                        target="_blank"
                                                                        class="text-primary"
                                                                    >
                                                                        <u>
                                                                            +{{
                                                                                item.phone
                                                                            }}
                                                                        </u>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr
                                                    class="border-top"
                                                    v-if="item.isShow"
                                                >
                                                    <td
                                                        class="ps-0 pe-0 w-80 fw-normal"
                                                    >
                                                        <div class="box note">
                                                            <div
                                                                class="d-flex align-items-center"
                                                            >
                                                                <div
                                                                    class="me-2"
                                                                >
                                                                    <img
                                                                        src="../../assets/note-yellow.png"
                                                                        width="30"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div>
                                                                    Notes dari
                                                                    CRM
                                                                    Specialist
                                                                    <div
                                                                        class="text-note fw-semibold"
                                                                    >
                                                                        {{
                                                                            item.note
                                                                        }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="fw-semibold mb-2 mt-2"
                                                        >
                                                            Informasi Barang
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-8">
                                                                <div
                                                                    class="d-flex"
                                                                >
                                                                    <div
                                                                        class="me-2"
                                                                    >
                                                                        <img
                                                                            src="../../assets/dimension.png"
                                                                            width="18"
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        {{
                                                                            item
                                                                                ?.dimension
                                                                                ?.width ??
                                                                            0
                                                                        }}cm X
                                                                        {{
                                                                            item
                                                                                ?.dimension
                                                                                ?.length ??
                                                                            0
                                                                        }}cm X
                                                                        {{
                                                                            item
                                                                                ?.dimension
                                                                                ?.height ??
                                                                            0
                                                                        }}cm
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    class="d-flex mt-2"
                                                                >
                                                                    <div
                                                                        class="me-2"
                                                                    >
                                                                        <img
                                                                            src="../../assets/notes.png"
                                                                            width="18"
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        {{
                                                                            item.description
                                                                        }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4">
                                                                <div
                                                                    class="d-flex"
                                                                >
                                                                    <div
                                                                        class="me-2"
                                                                    >
                                                                        <img
                                                                            src="../../assets/kg.png"
                                                                            width="18"
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        {{
                                                                            item.weight
                                                                        }}
                                                                        Kg
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div class="border-top">
                                            <div class="p-3 text-center">
                                                <a
                                                    href="javascript:void(0)"
                                                    @click="
                                                        showContentDone(index)
                                                    "
                                                    class="text-dark"
                                                >
                                                    <div
                                                        class="d-flex align-items-center justify-content-center"
                                                    >
                                                        <template
                                                            v-if="
                                                                item.isShow ==
                                                                true
                                                            "
                                                        >
                                                            <div>
                                                                Lihat Lebih
                                                                Sedikit
                                                            </div>
                                                            <i
                                                                class="fa fa-angle-up size-20 ms-1"
                                                            ></i>
                                                        </template>
                                                        <template
                                                            v-if="
                                                                item.isShow ==
                                                                false
                                                            "
                                                        >
                                                            <div>
                                                                Lihat Lebih
                                                                Banyak
                                                            </div>
                                                            <i
                                                                class="fa fa-angle-down size-20 ms-1"
                                                            ></i>
                                                        </template>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="text-center mt-3">
                                        <img
                                            src="../../assets/no-data-green.png"
                                            width="70"
                                            alt=""
                                        />
                                        <div class="mt-3 mb-4 text-gray">
                                            Tidak ada barang yang selesai
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalOnthewayConfirmation"></div>
            <div class="modal-body-chat vsm" v-if="modalOnthewayConfirmation">
                <div class="mt-4 text-center">
                    <img src="../../assets/info-yellow.png" width="80" alt="" />
                    <h4 class="mb-0 fw-bold mt-4">
                        Segera Berangkat ke Lokasi Penjemputan
                    </h4>
                </div>
                <div class="row">
                    <div class="col-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalOnthewayConfirmation = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-6 mt-4">
                        <button
                            @click="storeOntheway()"
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            :disabled="is_store_ontheway"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="is_store_ontheway"
                            ></span>
                            Ok
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalPickupConfirmation"></div>
            <div class="modal-body-chat vsm" v-if="modalPickupConfirmation">
                <div class="mt-4 text-center">
                    <img src="../../assets/info-yellow.png" width="80" alt="" />
                    <h4 class="mb-0 fw-bold mt-4">
                        Apakah Anda Yakin Sudah Menjemput Barang Ini?
                    </h4>
                </div>
                <div class="row">
                    <div class="col-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalPickupConfirmation = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-6 mt-4">
                        <button
                            @click="storePickup()"
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            :disabled="is_store_pickup"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="is_store_pickup"
                            ></span>
                            Ya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalConfirmationOffLoad"></div>
            <div class="modal-body-chat vsm" v-if="modalConfirmationOffLoad">
                <div class="mt-4 text-center">
                    <img src="../../assets/info-yellow.png" width="80" alt="" />
                    <h4 class="mb-0 fw-bold mt-4">
                        Apakah Anda Yakin Telah Menurunkan Semua Barang?
                    </h4>
                </div>
                <div class="row">
                    <div class="col-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalConfirmationOffLoad = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-6 mt-4">
                        <button
                            @click="storeOffLoad()"
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            :disabled="is_store_offload"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="is_store_offload"
                            ></span>
                            Ya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccessOffload"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccessOffload">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">
                        Proses Penugasan Driver Telah Selesai
                    </h4>
                </div>
                <div class="mt-4">
                    <a
                        href="javascript:void(0)"
                        @click="closeOffload()"
                        class="btn btn-primary w-100 btn-lg"
                    >
                        Tutup
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Api from "../../api/Api";
import moment from "moment";

export default {
    name: "pickupDriver",
    components: {},
    data() {
        return {
            moment: moment,
            list_process: {
                total: 0,
            },
            lists: [],
            modalOnthewayConfirmation: false,
            is_store_ontheway: false,
            delivery_assignation_id: null,
            modalPickupConfirmation: false,
            is_store_pickup: false,
            list_done: {
                total_pickup: 0,
                total_all: 0,
                is_done: null,
            },
            dones: [],
            tab_type: "process",
            is_store_offload: false,
            modalConfirmationOffLoad: false,
            modalSuccessOffload: false,
        };
    },
    created() {
        this.getProcessLists();
        this.getDoneLists();
    },
    computed: {},
    methods: {
        getTabValue(value) {
            this.tab_type = value;
        },
        showContent(index) {
            this.lists.forEach((list, i) => {
                list.isShow = i === index ? !list.isShow : false;
            });
        },
        showContentDone(index) {
            this.dones.forEach((done, i) => {
                done.isShow = i === index ? !done.isShow : false;
            });
        },
        getProcessLists() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/pickup/on_progress`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })

                .then((res) => {
                    this.list_process = res.data.data;
                    this.lists = res.data.data.list_data.map((item) => {
                        return {
                            ...item,
                            isShow: false,
                        };
                    });
                })
                .catch((err) => {
                    this.is_loading_data = false;
                    console.log(err);
                });
        },
        getDoneLists() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/pickup/done`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })

                .then((res) => {
                    this.list_done = res.data.data;
                    this.dones = res.data.data.list_data.map((item) => {
                        return {
                            ...item,
                            isShow: false,
                        };
                    });
                })
                .catch((err) => {
                    this.is_loading_data = false;
                    console.log(err);
                });
        },
        openModalOntheway(item) {
            this.delivery_assignation_id = item.delivery_assignation_id;
            this.modalOnthewayConfirmation = true;
        },
        storeOntheway() {
            this.is_store_ontheway = true;
            var data = {
                delivery_assignation_id: this.delivery_assignation_id,
                status: "ontheway",
            };

            Api.post(`${process.env.VUE_APP_SERVICE_URL}/pickup/update`, data, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.is_store_ontheway = false;
                    this.modalOnthewayConfirmation = false;
                    this.getProcessLists();
                    console.log(res);
                })
                .catch((err) => {
                    this.is_store_ontheway = false;
                    this.modalOnthewayConfirmation = false;
                    if (err.response.data.data == null) {
                        this.$toast.error(err.response.data.message, {
                            position: "top-right",
                            timeout: 2000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: false,
                            icon: true,
                            rtl: false,
                        });
                    } else {
                        this.$toast.error(err.response.data.data.message, {
                            position: "top-right",
                            timeout: 2000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: false,
                            icon: true,
                            rtl: false,
                        });
                    }
                });
        },
        openModalPickup(item) {
            this.delivery_assignation_id = item.delivery_assignation_id;
            this.modalPickupConfirmation = true;
        },
        storePickup() {
            this.is_store_pickup = true;
            var data = {
                delivery_assignation_id: this.delivery_assignation_id,
                status: "pickup",
            };

            Api.post(`${process.env.VUE_APP_SERVICE_URL}/pickup/update`, data, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.is_store_pickup = false;
                    this.modalPickupConfirmation = false;
                    this.tab_type = "done";
                    this.getDoneLists();
                    this.getProcessLists();
                    console.log(res);
                })
                .catch((err) => {
                    this.is_store_pickup = false;
                    this.modalPickupConfirmation = false;
                    if (err.response.data.data == null) {
                        this.$toast.error(err.response.data.message, {
                            position: "top-right",
                            timeout: 2000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: false,
                            icon: true,
                            rtl: false,
                        });
                    } else {
                        this.$toast.error(err.response.data.data.message, {
                            position: "top-right",
                            timeout: 2000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: false,
                            icon: true,
                            rtl: false,
                        });
                    }
                });
        },
        storeOffLoad() {
            this.is_store_offload = true;
            Api.post(`${process.env.VUE_APP_SERVICE_URL}/pickup/offload`, "", {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.is_store_offload = false;
                    this.modalConfirmationOffLoad = false;
                    this.modalSuccessOffload = true;
                    console.log(res);
                })
                .catch((err) => {
                    this.is_store_offload = false;
                    this.modalConfirmationOffLoad = false;
                    if (err.response.data.data == null) {
                        this.$toast.error(err.response.data.message, {
                            position: "top-right",
                            timeout: 2000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: false,
                            icon: true,
                            rtl: false,
                        });
                    } else {
                        this.$toast.error(err.response.data.data.message, {
                            position: "top-right",
                            timeout: 2000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: false,
                            icon: true,
                            rtl: false,
                        });
                    }
                });
        },
        closeOffload() {
            this.getDoneLists();
            this.getProcessLists();
            this.tab_type = "done";
            this.modalSuccessOffload = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    background: none;
    border-color: #40bf76;
    border-bottom: 2px solid #40bf76;
    border-radius: 0px;
    color: #40bf76;
    font-weight: 600;
    padding: 10px;
}
.nav-pills .nav-link {
    border-radius: 0px;
    color: #a3b4c2;
    border-bottom: 1px solid #e6e6e8;
    padding: 10px;
}
.header-card {
    background: #f5f5f5;
    width: 100%;
    padding: 14px;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
}
.mobile {
    font-size: 11px;
}
.box.note {
    background: #fff2e5;
    border: unset;
    border-radius: 5px;
    padding: 10px;
}
.text-note {
    color: #cc6c00;
}
</style>
