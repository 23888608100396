<template>
    <div class="padding-container">
        <div class="content-load" v-if="detail_loading"></div>
        <div id="loader" v-if="detail_loading"></div>
        <div class="fw-bold">
            <a
                href="javascript:void(0)"
                @click="$router.go(-1)"
                class="text-gray-dashboard size-16"
            >
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </a>
        </div>
        <div class="row mt-3">
            <div class="col-md-6">
                <div class="box">
                    <h4 class="mb-0 fw-bold">Informasi Pengirim</h4>
                    <table class="w-100 ps-0 fw-bold mt-3" cellpadding="10">
                        <tr class="border-bottom">
                            <td class="ps-0 w-150 fw-medium">Nama Pengirim</td>
                            <td class="w-30">:</td>
                            <td class="fw-bold">
                                {{
                                    dataDetail.information_sender.name
                                        ? dataDetail.information_sender.name
                                        : "-"
                                }}
                            </td>
                        </tr>
                        <tr class="border-bottom">
                            <td class="ps-0 w-150 fw-medium">
                                No. Telp Pengirim
                            </td>
                            <td class="w-30">:</td>
                            <td class="fw-bold">
                                +{{
                                    dataDetail.information_sender.phone
                                        ? dataDetail.information_sender.phone
                                        : "-"
                                }}
                            </td>
                        </tr>
                        <tr>
                            <td class="ps-0 w-150 fw-medium">
                                Alamat Pengirim
                            </td>
                            <td class="w-30">:</td>
                            <td class="fw-bold">
                                {{
                                    dataDetail.information_sender.address
                                        ? dataDetail.information_sender.address
                                        : "-"
                                }}
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="col-md-6">
                <div class="box">
                    <h4 class="mb-0 fw-bold">Informasi Penerima</h4>
                    <table class="w-100 ps-0 fw-bold mt-3" cellpadding="10">
                        <tr class="border-bottom">
                            <td class="ps-0 w-150 fw-medium">Nama Penerima</td>
                            <td class="w-30">:</td>
                            <td class="fw-bold">
                                {{
                                    dataDetail.information_receiver.name
                                        ? dataDetail.information_receiver.name
                                        : "-"
                                }}
                            </td>
                        </tr>
                        <tr class="border-bottom">
                            <td class="ps-0 w-150 fw-medium">
                                No. Telp Penerima
                            </td>
                            <td class="w-30">:</td>
                            <td class="fw-bold">
                                +{{
                                    dataDetail.information_receiver.phone
                                        ? dataDetail.information_receiver.phone
                                        : "-"
                                }}
                            </td>
                        </tr>
                        <tr>
                            <td class="ps-0 w-150 fw-medium">
                                Alamat Penerima
                            </td>
                            <td class="w-30">:</td>
                            <td class="fw-bold">
                                {{
                                    dataDetail.information_receiver.address
                                        ? dataDetail.information_receiver
                                              .address
                                        : "-"
                                }}
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="col-md-6 mt-3">
                <div class="box">
                    <h4 class="mb-0 fw-bold">Informasi Pesanan</h4>
                    <table class="w-100 ps-0 fw-bold mt-3" cellpadding="10">
                        <tr class="border-bottom">
                            <td class="ps-0 w-150 fw-medium">Tanggal Dibuat</td>
                            <td class="w-30">:</td>
                            <td class="fw-bold">
                                {{
                                    dataDetail.information_order.created_at
                                        ? dataDetail.information_order
                                              .created_at
                                        : "-"
                                }}
                            </td>
                        </tr>
                        <tr
                            class="border-bottom"
                            v-if="dataDetail.information_order.lead_id"
                        >
                            <td class="ps-0 w-150 fw-medium">Deal ID</td>
                            <td class="w-30">:</td>
                            <td class="fw-bold">
                                <a
                                    class="text-primary"
                                    :href="
                                        `/data/leads/detail?lead_id=` +
                                        dataDetail.information_order.lead_id
                                    "
                                >
                                    <u>
                                        {{
                                            dataDetail.information_order.deal_id
                                                ? dataDetail.information_order
                                                      .deal_id
                                                : "-"
                                        }}
                                        <i
                                            class="pi pi-arrow-up-right me-1 size-12"
                                        ></i>
                                    </u>
                                </a>
                            </td>
                        </tr>
                        <tr class="border-bottom">
                            <td class="ps-0 w-150 fw-medium">Order ID</td>
                            <td class="w-30">:</td>
                            <td class="fw-bold">
                                {{
                                    dataDetail.information_order.order_id
                                        ? dataDetail.information_order.order_id
                                        : "-"
                                }}
                            </td>
                        </tr>
                        <tr>
                            <td class="ps-0 w-150 fw-medium">Jenis Layanan</td>
                            <td class="w-30">:</td>
                            <td class="fw-bold capitalize">
                                {{
                                    dataDetail.information_order.service_type
                                        ? dataDetail.information_order.service_type
                                              .split("_")
                                              .join(" ")
                                        : "-"
                                }}
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="col-md-6 mt-3">
                <div class="box">
                    <h4 class="mb-0 fw-bold">Rincian Pembayaran</h4>
                    <table class="w-100 ps-0 fw-bold mt-3" cellpadding="10">
                        <tr class="border-bottom">
                            <td class="ps-0 w-150 fw-medium">
                                Biaya Pengiriman
                            </td>
                            <td class="w-30">:</td>
                            <td class="fw-bold">
                                <span class="fw-bold">
                                    {{
                                        currency(
                                            dataDetail.detail_payment.service
                                        )
                                    }}
                                </span>
                            </td>
                        </tr>
                        <tr class="border-bottom">
                            <td class="ps-0 w-150 fw-medium">Biaya Packing</td>
                            <td class="w-30">:</td>
                            <td class="fw-bold">
                                <span class="fw-bold">
                                    {{
                                        currency(
                                            dataDetail.detail_payment.packing
                                        )
                                    }}
                                </span>
                            </td>
                        </tr>
                        <tr class="border-bottom">
                            <td class="ps-0 w-150 fw-medium">
                                Biaya Jasa Tambahan
                            </td>
                            <td class="w-30">:</td>
                            <td class="fw-bold">
                                <span class="fw-bold">
                                    {{
                                        currency(
                                            dataDetail.detail_payment.additional
                                        )
                                    }}
                                </span>
                            </td>
                        </tr>
                        <tr class="border-bottom">
                            <td class="ps-0 w-150 fw-medium">Diskon</td>
                            <td class="w-30">:</td>
                            <td class="fw-bold">
                                <span class="fw-bold text-yellow">
                                    -{{
                                        currency(
                                            dataDetail.detail_payment.discount
                                        )
                                    }}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td class="ps-0 w-150 fw-medium">
                                Total Pembayaran
                            </td>
                            <td class="w-30">:</td>
                            <td class="fw-bold">
                                <span class="fw-bold text-green">
                                    {{
                                        currency(
                                            dataDetail.detail_payment.total
                                        )
                                    }}
                                </span>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <div class="box mt-4">
            <h4 class="fw-bold mb-0">Daftar Barang</h4>
            <div class="table-responsive-custom">
                <table class="table table-bordered mt-3">
                    <thead class="table-light">
                        <tr>
                            <th class="fw-medium">Koli</th>
                            <th class="fw-medium">
                                Deskripsi<span class="text-danger">*</span>
                            </th>
                            <th class="fw-medium">
                                Berat (Kg)<span class="text-danger">*</span>
                            </th>
                            <th class="fw-medium">
                                Panjang (Cm)<span class="text-danger">*</span>
                            </th>
                            <th class="fw-medium">
                                Lebar (Cm)<span class="text-danger">*</span>
                            </th>
                            <th class="fw-medium">
                                Tinggi (Cm)<span class="text-danger">*</span>
                            </th>
                            <th class="fw-medium">
                                Berat Volume (Kg)<span class="text-danger"
                                    >*</span
                                >
                            </th>
                            <th class="fw-medium">
                                Kubikasi (Kg3)<span class="text-danger">*</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody
                        class="padding-body"
                        v-for="(item, index) in dataDetail.items"
                        :key="index"
                    >
                        <tr>
                            <td class="custom">
                                <div class="form-box w-koli">
                                    {{ item.qty }}
                                </div>
                            </td>
                            <td class="custom">
                                <input
                                    type="text"
                                    :value="item.description"
                                    class="form-control"
                                    disabled
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    :title="item.description"
                                />
                            </td>
                            <td class="custom">
                                <div
                                    class="form-box"
                                    v-bind:class="{
                                        green: item.weight > item.weight_volume,
                                    }"
                                >
                                    {{ item.weight }}
                                </div>
                            </td>
                            <td class="custom">
                                <div class="form-box">{{ item.length }}</div>
                            </td>
                            <td class="custom">
                                <div class="form-box">{{ item.width }}</div>
                            </td>
                            <td class="custom">
                                <div class="form-box">{{ item.height }}</div>
                            </td>
                            <td class="custom">
                                <div
                                    class="form-box"
                                    v-bind:class="{
                                        green: item.weight_volume > item.weight,
                                    }"
                                >
                                    {{ customRound(item.weight_volume) }}
                                </div>
                            </td>
                            <td class="custom">
                                <div class="d-flex align-items-center">
                                    <div class="form-box">
                                        {{ customRound(item.cubic) }}
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="custom" colspan="8">
                                <div class="w-300">
                                    <label class="fw-medium mb-1">
                                        Perlindungan Barang
                                    </label>
                                    <div class="box radius-select">
                                        <div class="d-flex align-items-center">
                                            <div
                                                class="scroll-select capitalize"
                                            >
                                                {{
                                                    item.packing
                                                        .split("_")
                                                        .join(" ")
                                                }}
                                            </div>
                                            <div class="ms-auto">
                                                <img
                                                    src="../assets/angle-down.png"
                                                    class="ms-1"
                                                    width="14"
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <hr class="mt-3" />
            <h4 class="fw-bold mb-0">Hasil</h4>
            <div class="table-responsive-custom">
                <table class="result table table-bordered mt-3">
                    <thead class="table-light">
                        <tr>
                            <th scope="col" class="fw-medium">
                                Berat Aktual (Kg)
                            </th>
                            <th scope="col" class="fw-medium">
                                Berat Volume (Kg)
                            </th>
                            <th scope="col" class="fw-medium">
                                Kubikasi (Kg3)
                            </th>
                            <th scope="col" class="fw-medium">
                                Charge Weight (Kg)
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div class="form-box">
                                    {{ totalWeight }}
                                </div>
                            </td>
                            <td>
                                <div class="form-box">
                                    {{ totalVolume }}
                                </div>
                            </td>
                            <td>
                                <div class="form-box">
                                    {{ totalCubic }}
                                </div>
                            </td>
                            <td>
                                <div class="form-box green">
                                    {{ chargeWeight }}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../api/Api";
import { Tooltip } from "bootstrap";

export default {
    components: {},
    name: "OrderProcessDetail",
    data() {
        return {
            dataClaim: {},
            detail_loading: false,
            dataDetail: {
                information_sender: {},
                information_receiver: {},
                information_order: {},
                detail_payment: {},
                items: [],
                total_item: {},
            },
        };
    },
    created() {
        this.getDetail();
    },
    mounted() {
        const dataClaim = sessionStorage.getItem("dataClaim");
        this.dataClaim = JSON.parse(dataClaim);

        new Tooltip(document.body, {
            selector: "[data-bs-toggle='tooltip']",
        });
    },
    computed: {
        totalWeight() {
            return this.dataDetail.items.reduce(
                (total, item) => total + item.qty * Math.round(item.weight),
                0
            );
        },
        totalVolume() {
            return this.dataDetail.items.reduce(
                (total, item) =>
                    total + item.qty * Math.round(item.weight_volume),
                0
            );
        },
        totalCubic() {
            return this.dataDetail.items.reduce(
                (total, item) => total + item.qty * Math.round(item.cubic),
                0
            );
        },
        chargeWeight() {
            return Math.max(this.totalWeight, this.totalVolume);
        },
    },
    methods: {
        getDetail() {
            this.detail_loading = true;
            const dataClaim = sessionStorage.getItem("dataClaim");
            const paramsObj = JSON.parse(dataClaim);
            var payload = {
                order_id: paramsObj.order_id,
            };
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/order/detail`, {
                params: payload,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.dataDetail = res.data.data;
                    this.detail_loading = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.detail_loading = false;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.w-30 {
    width: 30px;
}
.w-150 {
    width: 150px;
}
.w-120 {
    width: 120px;
}
.table > :not(caption) > * > * {
    border: unset;
}
.table-bordered > :not(caption) > * {
    border: unset;
}
.table > :not(caption) > * > * {
    background-color: unset;
}
tr {
    border-radius: 10px !important;
}
tr td.custom {
    background-color: #ebf6ff !important;
}
.w-handling {
    width: 300px;
}
table tbody tr:first-child td:first-child {
    border-top-left-radius: 6px;
}

table tbody tr:first-child td:last-child {
    border-top-right-radius: 6px;
}

table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 6px;
}

table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 6px;
}
.padding-body::after {
    height: 10px;
    display: table-row;
    content: "";
}
.result.table > :not(caption) > * > * {
    padding: 0.2rem 0.5rem;
}
.form-control.green {
    background: #d9f2e4;
    border: 1px solid #8cd9ad !important;
    color: #329a5f !important;
}
.form-control.green[disabled] {
    background: #d9f2e4;
    border: 1px solid #8cd9ad !important;
    color: #329a5f !important;
}
.w-desc {
    width: 160px;
}
.w-koli {
    width: 60px;
}
.radius-select {
    border-radius: 10px;
    padding: 8px 12px;
    background: #f3f5f7;
    border: 1px solid #d1d0d4;
}
.w-300 {
    width: 300px;
}
.form-box {
    color: #000;
}
</style>
