<template>
    <div class="padding-container">
        <notifications group="foo" position="top right" />
        <div class="box">
            <div class="mb-3">
                <h3 class="fw-bold">Daftar Manifest</h3>
            </div>
            <div class="d-flex align-items-center no-flex mt-4">
                <div class="me-4 m-mx-0 m-mt-2">
                    <div class="position-relative">
                        <div class="input-group">
                            <input
                                type="text"
                                class="form-control form-control-lg pl-search w-search border-search"
                                placeholder="Cari Nomor Manifest"
                                v-model="search"
                            />
                            <button
                                class="btn btn-primary px-4"
                                type="button"
                                @click="filterData()"
                            >
                                Cari
                            </button>
                        </div>
                        <span class="search-icon left">
                            <i class="pi pi-search"></i>
                        </span>
                    </div>
                </div>
                <div class="m-mx-0 m-mt-2 me-3">
                    <button
                        class="btn btn-outline-yellow btn-filter px-3"
                        @click="isFilter = !isFilter"
                    >
                        <img
                            src="../assets/dashboard/filter.png"
                            class="me-1"
                            width="18"
                            alt=""
                        />
                        Filter
                    </button>
                </div>
                <div class="ms-auto">
                    <button
                        class="btn btn-primary px-3"
                        @click="showModalAddManifest()"
                    >
                        <i class="pi pi-plus me-1 size-12"></i>
                        Buat Manifest
                    </button>
                </div>
            </div>
            <div class="box mt-3 position-relative" v-if="isFilter">
                <div class="row">
                    <div class="col-md-4">
                        <label class="fw-bold mb-1">Kota Asal</label>
                        <multiselect
                            v-model="origin"
                            :options="origins"
                            label="name"
                            track-by="id"
                            placeholder="Cari dan pilih kota asal"
                            class="multi-form-custom"
                            :show-labels="false"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-8">
                        <label class="fw-bold mb-1">Kota Tujuan</label>
                        <div class="row">
                            <div class="col-md-6">
                                <multiselect
                                    v-model="destination"
                                    :options="destinations"
                                    label="name"
                                    track-by="id"
                                    placeholder="Cari dan pilih kota tujuan"
                                    class="multi-form-custom"
                                    :show-labels="false"
                                >
                                </multiselect>
                            </div>
                            <div class="col-md-4">
                                <div class="d-flex">
                                    <button
                                        class="btn btn-outline-yellow px-5 me-3"
                                        @click="clearFilter()"
                                        :disabled="
                                            origin == null &&
                                            destination == null
                                        "
                                    >
                                        Clear
                                    </button>
                                    <button
                                        class="btn btn-green px-5"
                                        :disabled="
                                            origin == null &&
                                            destination == null
                                        "
                                        @click="filterData()"
                                    >
                                        Filter
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex align-items-center mt-4">
                <div>
                    Menampilkan
                    <span class="fw-bold">1-{{ limit }}</span> Data dari
                    <span class="fw-bold">{{ lists.list_data.length }}</span>
                    Data
                </div>
                <div class="ms-auto">
                    <div class="d-flex align-items-center">
                        <div class="me-2">Baris per halaman</div>
                        <select
                            class="form-control form-select w-select"
                            v-model="limit"
                        >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="list_loading">
                <ListLoader />
            </div>
            <div v-else>
                <div class="table-responsive-custom">
                    <table class="table table-bordered mt-4">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">No</th>
                                <th class="text-center" scope="col">
                                    Nomor Manifest
                                </th>
                                <th class="text-center" scope="col">
                                    Kota Asal
                                </th>
                                <th class="text-center" scope="col">
                                    Kota Tujuan
                                </th>
                                <th class="text-center" scope="col">
                                    Perkiraan Waktu Tiba
                                </th>
                                <th class="text-center" scope="col">
                                    Status Manifest
                                </th>
                                <th class="text-center" scope="col">
                                    Edit Status
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(item, index) in lists.list_data"
                                :key="index"
                            >
                                <td class="text-center">{{ index + 1 }}</td>
                                <td>
                                    <a
                                        href="javascript:void(0)"
                                        @click="showDataDetail(item)"
                                        class="text-link"
                                    >
                                        {{ item.manifest_code }}
                                    </a>
                                </td>
                                <td>{{ item.origin_regency }}</td>
                                <td>{{ item.destination_regency }}</td>
                                <td>{{ item.estimated_time }}</td>
                                <td class="text-center">
                                    <span
                                        class="tag mb-0 pb-0 radius me-0"
                                        v-bind:class="{
                                            'yellow-cro':
                                                item.status == 'process',
                                            'blue-cro':
                                                item.status == 'ontheway',
                                            'green-cro':
                                                item.status == 'finish',
                                            'red-cro': item.status == 'pending',
                                        }"
                                    >
                                        <template
                                            v-if="item.status == 'process'"
                                        >
                                            Dalam Proses
                                        </template>
                                        <template
                                            v-if="item.status == 'ontheway'"
                                        >
                                            Dalam Perjalanan
                                        </template>
                                        <template
                                            v-if="item.status == 'pending'"
                                        >
                                            Tertunda
                                        </template>
                                        <template
                                            v-if="item.status == 'finish'"
                                        >
                                            Selesai
                                        </template>
                                    </span>
                                </td>
                                <td class="text-center">
                                    <img
                                        src="../assets/dashboard/edit-icon.png"
                                        class="grayscale"
                                        width="18"
                                        v-if="item.status == 'finish'"
                                    />
                                    <a
                                        href="javascript:void(0)"
                                        @click="showModalEditManifest(item)"
                                        v-else
                                    >
                                        <img
                                            src="../assets/dashboard/edit-icon.png"
                                            width="18"
                                            alt=""
                                        />
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div
                    v-if="lists.list_data.length == 0"
                    class="text-center mt-5"
                >
                    <img src="../assets/no-data-green.png" width="100" alt="" />
                    <div class="mt-4 mb-4 text-gray">
                        Belum ada daftar manifest
                    </div>
                </div>
            </div>
            <div class="d-flex mt-4">
                <div class="ms-auto">
                    <b-pagination-nav
                        v-if="lists.list_data.length > 0"
                        v-model="currentPage"
                        :number-of-pages="totalPage"
                        base-url="#"
                        first-number
                        align="right"
                        @input="getLists()"
                    ></b-pagination-nav>
                </div>
            </div>
        </div>
        <!-- modal -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalAddManifest"></div>
            <div class="modal-body-chat md" v-if="modalAddManifest">
                <div class="mt-3 mb-3">
                    <h4 class="mb-0 fw-bold">Buat Manifest</h4>
                    <div class="row mt-3">
                        <div class="col-md-6">
                            <label class="fw-bold mb-1">
                                Kota Asal
                                <span class="text-danger">*</span>
                            </label>
                            <multiselect
                                v-model="origin_create"
                                :options="origins"
                                label="name"
                                track-by="id"
                                placeholder="Cari dan pilih kota asal"
                                class="multi-form-custom"
                                :show-labels="false"
                            >
                            </multiselect>
                        </div>
                        <div class="col-md-6">
                            <label class="fw-bold mb-1">
                                Kota Tujuan
                                <span class="text-danger">*</span>
                            </label>
                            <multiselect
                                v-model="destination_create"
                                :options="destinations"
                                label="name"
                                track-by="id"
                                placeholder="Cari dan pilih kota tujuan"
                                class="multi-form-custom"
                                :show-labels="false"
                            >
                            </multiselect>
                        </div>
                        <div class="col-md-12 mt-3">
                            <label class="fw-bold mb-1">
                                Pilih resi yang akan ditambahkan pada manifest
                                <span class="text-danger">*</span>
                            </label>
                            <div
                                class="box mt-2"
                                v-bind:class="{
                                    'scroll-manifest': receipts.length > 3,
                                }"
                            >
                                <template v-if="receipts.length > 0">
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        v-model="selectAllManifest"
                                        id="allCheck"
                                    />
                                    <label
                                        class="form-check-label fw-semibold ms-1 mb-0"
                                        for="allCheck"
                                    >
                                        Pilih Semua Resi
                                    </label>
                                    <div class="mt-2">
                                        <ul class="select">
                                            <li
                                                class="li-hide mt-2"
                                                v-for="(
                                                    receipt, index
                                                ) in receipts"
                                                :key="index"
                                            >
                                                <b-form-group
                                                    v-slot="{ ariaDescribedby }"
                                                >
                                                    <b-form-checkbox-group
                                                        id="checkbox-group-2"
                                                        v-model="valueCheckBox"
                                                        :aria-describedby="
                                                            ariaDescribedby
                                                        "
                                                        name="flavour-2"
                                                    >
                                                        <b-form-checkbox
                                                            class="d-flex align-items-center"
                                                            :value="receipt"
                                                        >
                                                            <div
                                                                class="ms-2 text-gray-dashboard"
                                                            >
                                                                {{
                                                                    receipt.receipt_code
                                                                }}
                                                            </div>
                                                        </b-form-checkbox>
                                                    </b-form-checkbox-group>
                                                </b-form-group>
                                                <div class="row">
                                                    <div
                                                        class="col-md-3 text-gray-dashboard"
                                                    >
                                                        <div
                                                            class="d-flex align-items-center"
                                                        >
                                                            <img
                                                                src="../assets/total_koli.png"
                                                                width="14"
                                                                height="14"
                                                                class="me-1"
                                                                alt=""
                                                            />
                                                            {{
                                                                receipt.total_qty
                                                            }}Koli
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="col-md-3 text-gray-dashboard"
                                                    >
                                                        <div
                                                            class="d-flex align-items-center"
                                                        >
                                                            <img
                                                                src="../assets/total_weight.png"
                                                                width="14"
                                                                height="14"
                                                                class="me-1"
                                                                alt=""
                                                            />
                                                            {{
                                                                receipt.total_weight
                                                            }}Kg
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="col-md-3 text-gray-dashboard"
                                                    >
                                                        <div
                                                            class="d-flex align-items-center"
                                                        >
                                                            <img
                                                                src="../assets/total_weight_volume.png"
                                                                width="14"
                                                                height="14"
                                                                class="me-1"
                                                                alt=""
                                                            />
                                                            {{
                                                                receipt.total_weight_volume
                                                            }}Kg
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="col-md-3 text-gray-dashboard"
                                                    >
                                                        <div
                                                            class="d-flex align-items-center"
                                                        >
                                                            <img
                                                                src="../assets/total_cubic.png"
                                                                width="16"
                                                                height="16"
                                                                class="me-1"
                                                                alt=""
                                                            />
                                                            {{
                                                                receipt.total_cubic
                                                            }}Kg3
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="text-center">
                                        <img
                                            src="../assets/no-data-green.png"
                                            width="100"
                                            alt=""
                                        />
                                        <div class="mt-4 mb-1 text-gray">
                                            Masukkan kota asal dan kota tujuan
                                            terlebih dahulu
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div class="col-md-12 mt-3">
                            <label class="fw-bold mb-1">
                                Perkiraan Waktu Tiba
                                <span class="text-danger">*</span>
                            </label>
                            <date-picker
                                v-model="date"
                                type="date"
                                value-type="format"
                                class="w-100"
                                format="DD MMMM YYYY"
                                placeholder="Atur perkiraan waktu tiba"
                            >
                            </date-picker>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="closeModalAddManifest()"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Batal
                        </a>
                    </div>
                    <div class="col-md-6 mt-4">
                        <button
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            @click="
                                (modalAddManifestConfirmation = true),
                                    (modalAddManifest = false)
                            "
                            :disabled="
                                origin_create == null ||
                                destination_create == null ||
                                valueCheckBox == [] ||
                                date == ''
                            "
                        >
                            Buat Manifest
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalAddManifestConfirmation"></div>
            <div
                class="modal-body-chat vsm"
                v-if="modalAddManifestConfirmation"
            >
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h4 class="mb-0 fw-bold mt-4">Manifest Akan Dibuat</h4>
                    <div class="text-gray-dashboard">
                        Apakah Anda yakin ingin membuat manifest ini?
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="
                                (modalAddManifestConfirmation = false),
                                    (modalAddManifest = true)
                            "
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-md-6 mt-4">
                        <button
                            @click="createManifest()"
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            :disabled="is_create_manifest"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="is_create_manifest"
                            ></span>
                            Ya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalEditManifest"></div>
            <div class="modal-body-chat md" v-if="modalEditManifest">
                <div class="mt-3 mb-3">
                    <h4 class="mb-0 fw-bold">
                        Update Status Manifest - {{ dataEdit.manifest_code }}
                    </h4>
                    <div class="mt-3">
                        <label class="fw-bold mb-1">
                            Status Manifest Terkini
                        </label>
                        <div class="form-box text-dark">
                            <template v-if="dataEdit.status === 'process'">
                                Dalam Proses
                            </template>
                            <template v-if="dataEdit.status === 'ontheway'">
                                Dalam Perjalanan
                            </template>
                            <template v-if="dataEdit.status === 'finish'">
                                Selesai
                            </template>
                        </div>
                        <div class="mt-3">
                            <label class="fw-bold mb-1">
                                Perubahan Status Manifest
                                <span class="text-danger">*</span>
                            </label>
                            <multiselect
                                v-model="status"
                                :options="statuses"
                                label="label"
                                track-by="id"
                                placeholder="Pilih status manifest"
                                class="multi-form-custom"
                                :show-labels="false"
                            >
                            </multiselect>
                        </div>
                        <div
                            class="col-md-12 mt-3"
                            v-if="dataEdit.status !== 'process'"
                        >
                            <label class="fw-bold mb-1">
                                Perkiraan Waktu Tiba
                                <span class="text-danger">*</span>
                            </label>
                            <date-picker
                                v-model="estimated_time"
                                type="date"
                                value-type="format"
                                class="w-100"
                                format="DD MMMM YYYY"
                                placeholder="Atur perkiraan waktu tiba"
                            >
                            </date-picker>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="
                                (modalEditManifest = false), (status = null)
                            "
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Batal
                        </a>
                    </div>
                    <div class="col-md-6 mt-4">
                        <button
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            :disabled="is_update_manifest || status == null"
                            @click="updateManifest()"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="is_update_manifest"
                            ></span>
                            Update
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccessUpdate"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccessUpdate">
                <div class="text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">
                        Status Pengiriman Barang Berhasil Diupdate
                    </h4>
                </div>
                <div class="mt-4">
                    <a
                        href="/manifest"
                        type="button"
                        class="btn btn-primary w-100 btn-lg"
                    >
                        Tutup
                    </a>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">Manifest Berhasil Dibuat</h4>
                </div>
                <div class="mt-4">
                    <a href="/manifest" class="btn btn-primary w-100 btn-lg">
                        Tutup
                    </a>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalDetailManifest"></div>
            <div class="modal-body-chat md" v-if="modalDetailManifest">
                <div
                    class="close-position cursor-pointer"
                    @click="modalDetailManifest = false"
                >
                    <i class="pi pi-times-circle text-danger size-24"></i>
                </div>
                <div class="mt-3 mb-3">
                    <h4 class="mb-0 fw-bold">
                        Detail Manifest - {{ dataDetail.manifest_code }}
                    </h4>
                    <div class="mt-3">
                        <table class="w-100 ps-0 fw-bold mt-3" cellpadding="10">
                            <tr class="border-bottom">
                                <td class="ps-0 w-150 fw-medium">Kota Asal</td>
                                <td class="w-30">:</td>
                                <td class="fw-bold">
                                    {{ dataDetail.origin_regency }}
                                </td>
                            </tr>
                            <tr class="border-bottom">
                                <td class="ps-0 w-150 fw-medium">
                                    Kota Tujuan
                                </td>
                                <td class="w-30">:</td>
                                <td class="fw-bold">
                                    {{ dataDetail.destination_regency }}
                                </td>
                            </tr>
                            <tr class="border-bottom">
                                <td class="ps-0 w-150 fw-medium">
                                    Daftar Resi
                                </td>
                                <td class="w-30">:</td>
                                <td class="fw-bold">
                                    <div
                                        v-for="(
                                            receipt, index
                                        ) in dataDetail.orders"
                                        :key="index"
                                    >
                                        {{ receipt.receipt }}
                                    </div>
                                </td>
                            </tr>
                            <tr class="border-bottom">
                                <td class="ps-0 w-150 fw-medium">
                                    Perkiraan Waktu Tiba
                                </td>
                                <td class="w-30">:</td>
                                <td class="fw-bold">
                                    {{ dataDetail.estimated_time }}
                                </td>
                            </tr>
                            <tr class="border-bottom">
                                <td class="ps-0 w-150 fw-medium">
                                    Status Manifest
                                </td>
                                <td class="w-30">:</td>
                                <td class="fw-bold">
                                    <span
                                        class="tag mb-0 pb-0 radius me-0"
                                        v-bind:class="{
                                            'yellow-cro':
                                                dataDetail.status == 'process',
                                            'blue-cro':
                                                dataDetail.status == 'ontheway',
                                            'green-cro':
                                                dataDetail.status == 'finish',
                                        }"
                                    >
                                        <template
                                            v-if="
                                                dataDetail.status == 'process'
                                            "
                                        >
                                            Dalam Proses
                                        </template>
                                        <template
                                            v-if="
                                                dataDetail.status == 'ontheway'
                                            "
                                        >
                                            Dalam Perjalanan
                                        </template>
                                        <template
                                            v-if="dataDetail.status == 'finish'"
                                        >
                                            Selesai
                                        </template>
                                    </span>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import Api from "../api/Api";
import ListLoader from "../components/ListLoader.vue";
import DatePicker from "vue2-datepicker";
import moment from "moment";

export default {
    components: {
        Multiselect,
        ListLoader,
        DatePicker,
    },
    name: "Manifest",
    data() {
        return {
            moment: moment,
            isFilter: false,
            search: "",
            limit: 10,
            totalPage: 0,
            currentPage: 0,
            lists: {
                list_data: [],
            },
            origin: null,
            origins: [],
            destination: null,
            destinations: [],
            list_loading: false,
            origin_create: null,
            destination_create: null,
            modalAddManifest: false,
            valueCheckBox: [],
            receipts: [],
            date: "",
            modalEditManifest: false,
            status: null,
            statuses: [
                {
                    id: "process",
                    label: "Dalam Proses",
                },
                {
                    id: "ontheway",
                    label: "Dalam Perjalanan",
                },
                {
                    id: "finish",
                    label: "Selesai",
                },
            ],
            modalAddManifestConfirmation: false,
            is_create_manifest: false,
            order_id: [],
            modalSuccess: false,
            dataEdit: null,
            modalSuccessUpdate: false,
            is_update_manifest: false,
            modalDetailManifest: false,
            dataDetail: null,
            estimated_time: "",
        };
    },
    created() {
        this.getRegency();
        this.getLists();
        this.getReceipt();
    },
    mounted() {},
    computed: {
        selectAllManifest: {
            get() {
                return this.valueCheckBox.length === this.receipts.length;
            },
            set(value) {
                this.valueCheckBox = [];

                if (value) {
                    this.receipts.forEach((select) => {
                        this.valueCheckBox.push(select);
                    });
                }
            },
        },
    },
    methods: {
        getLists() {
            let payload = {
                search: this.search,
                limit: this.limit,
                page:
                    this.currentPage == 0 || this.currentPage == null
                        ? 0
                        : this.currentPage - 0,
            };
            if (this.origin) {
                payload.origin_regency_id = this.origin?.id;
            }
            if (this.destination) {
                payload.destination_regency_id = this.destination?.id;
            }
            this.list_loading = true;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/manifest`, {
                params: payload,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.lists = res.data.data;
                    this.totalPage = res.data.data.total_page;
                    this.list_loading = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.list_loading = false;
                });
        },
        getReceipt() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/manifest/receipts`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.receipts = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        createManifest() {
            this.is_create_manifest = true;
            this.valueCheckBox.forEach((val) => {
                this.order_id.push(val.order_id);
            });

            // Check if status is "process" and handle the estimated time accordingly
            const statusIsInProcess = this.status?.id === "process";
            var data = {
                origin_regency_id: this.origin_create?.id,
                destination_regency_id: this.destination_create?.id,
                order_id: this.order_id,
                estimated_time: statusIsInProcess
                    ? ""
                    : moment(this.date).format("YYYY-MM-DD"),
            };

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/manifest/create`,
                data,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    this.is_create_manifest = false;
                    this.modalAddManifestConfirmation = false;
                    this.modalSuccess = true;
                })
                .catch((err) => {
                    this.is_create_manifest = false;
                    this.modalAddManifestConfirmation = false;
                    this.origin_create = null;
                    this.destination_create = null;
                    this.valueCheckBox = [];
                    this.date = "";
                    if (err.response.data.data == null) {
                        this.$toast.error(err.response.data.message, {
                            position: "top-right",
                            timeout: 2000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: false,
                            icon: true,
                            rtl: false,
                        });
                    } else {
                        this.$toast.error(err.response.data.data.message, {
                            position: "top-right",
                            timeout: 2000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: false,
                            icon: true,
                            rtl: false,
                        });
                    }
                });
        },
        updateManifest() {
            this.is_update_manifest = true;
            var data = {
                manifest_code: this.dataEdit?.manifest_code,
                status: this.status?.id,
            };

            // Handle status changes
            if (this.status?.id === "process") {
                // Perform actions for "Dalam Proses" status if needed
            }

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/manifest/update`,
                data,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    this.is_update_manifest = false;
                    this.modalEditManifest = false;
                    this.modalSuccessUpdate = true;
                })
                .catch((err) => {
                    this.modalEditManifest = false;
                    this.status = null;
                    if (err.response.data.data == null) {
                        this.$toast.error(err.response.data.message, {
                            position: "top-right",
                            timeout: 2000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: false,
                            icon: true,
                            rtl: false,
                        });
                    } else {
                        this.$toast.error(err.response.data.data.message, {
                            position: "top-right",
                            timeout: 2000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: false,
                            icon: true,
                            rtl: false,
                        });
                    }
                });
        },
        showModalAddManifest() {
            this.modalAddManifest = true;
        },
        closeModalAddManifest() {
            this.modalAddManifest = false;
            this.valueCheckBox = [];
        },
        showModalEditManifest(item) {
            this.dataEdit = item;
            this.modalEditManifest = true;
        },
        clearFilter() {
            this.origin = null;
            this.destination = null;
            this.getLists();
        },
        filterData() {
            this.getLists();
        },
        getRegency() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/geo/regency`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.origins = data;
                    this.destinations = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        showDataDetail(item) {
            this.dataDetail = item;
            this.modalDetailManifest = true;
        },
    },
};
</script>

<style lang="scss" scoped>
ul li.li-hide {
    list-style-type: none;
}
ul.select {
    padding-left: 15px;
    margin-bottom: 0px;
}
.scroll-manifest {
    height: 200px;
    overflow-y: scroll;
}
.form-check-input {
    border: 1.5px solid #767676;
}
.grayscale {
    filter: grayscale(100%);
}
.w-150 {
    width: 150px;
}
.w-30 {
    width: 30px;
}
</style>
