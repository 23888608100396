<template>
    <div class="padding-container">
        <notifications group="foo" position="top right" />
        <div class="fw-bold">
            <a href="/data/leads" class="text-gray-dashboard size-16">
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </a>
        </div>
        <div class="box mt-4">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold mb-0">Tambah Data Leads</h3>
            </div>
            <div class="row mt-5">
                <div class="col-md-6">
                    <label class="fw-bold mb-1">
                        Nama Customer
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Masukkan nama customer"
                        v-model="req.name"
                    />
                </div>
                <div class="col-md-6 m-mt-1">
                    <label class="fw-bold mb-1"
                        >Nomor Handphone
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <div class="input-group mb-0">
                        <span class="input-group-text">+62</span>
                        <input
                            type="text"
                            class="form-control form-phone"
                            placeholder="Masukkan nomor telepon"
                            v-model="req.phone"
                            @input="validatePhoneWithPrefix"
                        />
                    </div>
                </div>
                <div class="col-md-6 mt-3 m-mt-1">
                    <label class="fw-bold mb-1">
                        Email
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Masukkan email"
                        v-model="req.email"
                    />
                </div>
                <div class="col-md-6 mt-3 m-mt-1">
                    <label class="fw-bold mb-1">Perusahaan</label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Masukkan perusahaan"
                        v-model="req.company_name"
                    />
                </div>
                <div class="col-md-6 mt-3 m-mt-1">
                    <label class="fw-bold mb-1">Jabatan</label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Masukkan jabatan"
                        v-model="req.position"
                    />
                </div>
                <div class="col-md-6 mt-3 m-mt-1">
                    <label class="fw-bold mb-1">Nomor telepon perusahaan</label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Masukkan nomor telepon perusahaan"
                        v-model="req.company_phone"
                        @input="validateNumber()"
                    />
                </div>
                <div class="col-md-12 mt-3 m-mt-1">
                    <label class="fw-bold mb-1">Alamat</label>
                    <textarea
                        cols="30"
                        rows="4"
                        class="form-control"
                        placeholder="Masukkan alamat"
                        v-model="req.address"
                    ></textarea>
                </div>
                <div class="col-md-6 mt-3 m-mt-1">
                    <label class="fw-bold mb-1">
                        Darimana Customer Tahu Tentang Perusahaan?
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <select
                        class="form-control form-select"
                        v-model="req.data_source"
                    >
                        <option value="">Pilih sumber informasi</option>
                        <option value="web">Web</option>
                        <option value="proposal">Proposal</option>
                        <option value="flyer">Flyer</option>
                        <option value="rekomendasi">Rekomendasi</option>
                        <option value="telepon">Telepon</option>
                        <option value="instagram">Instagram</option>
                        <option value="facebook">Facebook</option>
                    </select>
                </div>
                <div class="col-md-6 mt-3 m-mt-1">
                    <label class="fw-bold mb-1">Catatan</label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Masukkan catatan"
                        v-model="req.note"
                    />
                </div>
                <div class="col-md-12 mt-3 m-mt-1">
                    <label class="fw-bold mb-1">Tags</label>
                    <div class="mb-2">
                        <button
                            class="btn btn-sm btn-green-tag me-2"
                            :disabled="req.tags.includes(`Kirim Barang`)"
                            @click="addTrawlpackTags()"
                        >
                            Kirim Barang
                        </button>
                        <button
                            class="btn btn-sm btn-green-tag me-2"
                            :disabled="req.tags.includes(`Sewa Truk`)"
                            @click="addTrawltruckTags()"
                        >
                            Sewa Truk
                        </button>
                        <button
                            class="btn btn-sm btn-green-tag me-2"
                            :disabled="req.tags.includes(`Kirim Mobil`)"
                            @click="addTrawlcarrierTags()"
                        >
                            Kirim Mobil
                        </button>
                        <button
                            class="btn btn-sm btn-green-tag"
                            :disabled="req.tags.includes(`Kirim Kontainer`)"
                            @click="addSewaContainerTags()"
                        >
                            Kirim Kontainer
                        </button>
                    </div>
                    <input-tag
                        v-model="req.tags"
                        class="input-tag mb-3"
                        placeholder="Pilih tags"
                    ></input-tag>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-12 m-mt-3">
                    <div class="d-flex">
                        <div class="ms-auto">
                            <a
                                href="/data/leads"
                                class="btn btn-outline-yellow btn-lg px-5 me-3"
                                >Cancel</a
                            >
                            <button
                                class="btn btn-primary btn-lg px-5 me-3"
                                type="button"
                                :disabled="
                                    req.name == '' ||
                                    req.phone == '' ||
                                    req.email == ''
                                "
                                @click="modalSuccessConfirmation = true"
                            >
                                Simpan
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- modal -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccessConfirmation"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccessConfirmation">
                <div class="mt-4 text-center">
                    <img src="../../assets/info-yellow.png" width="80" alt="" />
                    <h4 class="mb-0 fw-bold mt-4">
                        Data Leads Akan Ditambahkan
                    </h4>
                    <div class="text-gray-dashboard">
                        Pastikan data yang diisi sudah benar
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalSuccessConfirmation = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Batal
                        </a>
                    </div>
                    <div class="col-md-6 mt-4">
                        <button
                            @click="storeData()"
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            :disabled="is_save"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="is_save"
                            ></span>
                            Simpan
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">
                        Data Leads Berhasil Ditambahkan
                    </h4>
                </div>
                <div class="mt-4">
                    <a href="/data/leads" class="btn btn-primary w-100 btn-lg">
                        Tutup
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import InputTag from "vue-input-tag";

export default {
    components: {
        InputTag,
    },
    name: "leadsCreate",
    data() {
        return {
            req: {
                name: "",
                phone: "",
                email: "",
                company_phone: "",
                company_name: "",
                position: "",
                address: "",
                data_source: "",
                tags: [],
                note: "",
            },
            data: "",
            id: null,
            is_save: false,
            is_save_prospect: false,
            modalSuccessConfirmation: false,
            modalSuccess: false,
        };
    },
    created() {
        this.data = this.$route.params.item;
    },
    methods: {
        validatePhoneWithPrefix() {
            if (!this.req.phone.startsWith("8")) {
                this.req.phone = "8" + this.req.phone.replace(/[^0-9]/g, "");
            } else {
                this.req.phone = this.req.phone.replace(/[^0-9]/g, "");
            }
        },
        validateNumber() {
            this.req.company_phone = this.req.company_phone.replace(
                /[^0-9]/g,
                ""
            );
        },
        addTrawlpackTags() {
            this.req.tags.push("Kirim Barang");
        },
        addSewaContainerTags() {
            this.req.tags.push("Kirim Kontainer");
        },
        addTrawltruckTags() {
            this.req.tags.push("Sewa Truk");
        },
        addTrawlcarrierTags() {
            this.req.tags.push("Kirim Mobil");
        },
        storeData() {
            this.is_save = true;

            var data = {
                name: this.req.name,
                phone: this.req.phone,
                email: this.req.email,
                company_name: this.req.company_name,
                company_phone: this.req.company_phone,
                position: this.req.position,
                address: this.req.address,
                data_source: this.req.data_source,
                tags: this.req.tags,
                note: this.req.note,
            };

            Api.post(`${process.env.VUE_APP_SERVICE_URL}/leads/create`, data, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.is_save = false;
                    this.modalSuccess = true;
                    this.modalSuccessConfirmation = false;
                    console.log(res);
                })
                .catch((err) => {
                    this.is_save = false;
                    if (err.response.data.data == null) {
                        this.$toast.error(err.response.data.message, {
                            position: "top-right",
                            timeout: 2000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: false,
                            icon: true,
                            rtl: false,
                        });
                    } else {
                        this.$toast.error(err.response.data.data.message, {
                            position: "top-right",
                            timeout: 2000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: false,
                            icon: true,
                            rtl: false,
                        });
                    }
                });
        },
    },
};
</script>

<style scoped>
.text-main-color {
    color: #24516a !important;
}
.text-gray-dashboard {
    color: #61616a;
}
.money-custom {
    height: 42px;
    font-size: 16px;
    padding-left: 15px;
}
.vue-input-tag-wrapper {
    padding-left: 10px !important;
    border-radius: 7px !important;
    border: 1px solid #c1cdd7 !important;
}
.form-control {
    padding: 8px 12px !important;
}
</style>
