<template>
    <div class="padding-container">
        <div class="content-load" v-if="is_loading_data"></div>
        <div id="loader" v-if="is_loading_data"></div>
        <notifications group="foo" position="top right" />
        <div class="fw-bold">
            <a
                href="javascript:void(0)"
                @click="$router.go(-1)"
                class="text-gray-dashboard size-16"
            >
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </a>
        </div>
        <div class="box mt-4">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold mb-0">Tambah Aktivitas Follow Up</h3>
            </div>
            <div class="row mt-5">
                <div class="col-md-12">
                    <label class="fw-bold mb-1">
                        Aktivitas
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <select
                        class="form-control form-select"
                        v-model="req.activity"
                    >
                        <option value="">Pilih aktivitas</option>
                        <option value="call">Telfon</option>
                        <option value="whatsapp">Chat WhatsApp</option>
                        <option value="other">Lainnya</option>
                    </select>
                </div>
                <div class="col-md-12 mt-3" v-if="req.activity">
                    <label class="fw-bold mb-1">
                        Deskripsi Aktivitas
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <textarea
                        cols="30"
                        rows="2"
                        class="form-control"
                        placeholder="Masukkan deskripsi aktivitas"
                        v-model="req.description_activity"
                    ></textarea>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-1">
                        Nama Customer
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <input
                        type="text"
                        class="form-control"
                        :value="detailLeads.name"
                        placeholder="Masukkan nama customer"
                        disabled
                    />
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-1"
                        >Nomor Handphone
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <div class="input-group mb-0">
                        <span class="input-group-text">+62</span>
                        <input
                            type="text"
                            class="form-control form-phone"
                            :value="detailLeads.phone_parser"
                            placeholder="Masukkan nomor telepon"
                            disabled
                        />
                    </div>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-1">
                        Prioritas
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <select
                        class="form-control form-select"
                        v-model="req.priority"
                    >
                        <option value="">Pilih prioritas</option>
                        <option value="low">Rendah</option>
                        <option value="high">Tinggi</option>
                    </select>
                </div>
                <div class="col-md-6 mt-3" v-show="isRole(managerRoles)">
                    <label class="fw-bold mb-1">
                        CRM Specialist
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <multiselect
                        v-model="specialist"
                        :options="specialists"
                        label="name"
                        track-by="id"
                        placeholder="Pilih CRM specialist"
                        class="multi-form-custom"
                        :show-labels="false"
                    >
                    </multiselect>
                </div>
                <div class="col-md-12 mt-3">
                    <label class="fw-bold mb-2"> Apakah ada prediksi? </label>
                    <div class="d-flex align-items-center">
                        <div class="form-check me-4">
                            <input
                                class="form-check-input"
                                type="radio"
                                name="presiction_question"
                                id="true"
                                :value="true"
                                v-model="req.is_prediction"
                            />
                            <label class="form-check-label" for="true">
                                Ada
                            </label>
                        </div>
                        <div class="form-check">
                            <input
                                class="form-check-input"
                                type="radio"
                                name="presiction_question"
                                id="false"
                                :value="false"
                                v-model="req.is_prediction"
                            />
                            <label class="form-check-label" for="false">
                                Tidak Ada
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-if="req.is_prediction == true">
                <div class="col-md-12 mt-3">
                    <label class="fw-bold mb-1">
                        Jenis Layanan
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <select
                        class="form-control form-select"
                        v-model="req.service"
                    >
                        <option value="">Pilih jenis layanan</option>
                        <option value="pengiriman_barang">
                            Pengiriman Barang
                        </option>
                        <option value="pengiriman_motor">
                            Pengiriman Motor
                        </option>
                        <option value="pengiriman_mobil">
                            Pengiriman Mobil
                        </option>
                        <option value="pengiriman_truk">Pengiriman Truk</option>
                        <option value="pengiriman_via_kontainer">
                            Pengiriman Via Kontainer
                        </option>
                    </select>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-1">
                        Kota/Kab. Asal
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <multiselect
                        v-model="req.regency"
                        :options="regencies"
                        label="name"
                        track-by="id"
                        placeholder="Cari kota/kabupaten"
                        class="multi-form-custom"
                        :show-labels="false"
                    >
                    </multiselect>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-1">
                        Kota/Kab. Tujuan
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <multiselect
                        v-model="req.destination"
                        :options="destinations"
                        label="name"
                        track-by="id"
                        placeholder="Cari kota/kabupaten"
                        class="multi-form-custom"
                        :show-labels="false"
                    >
                    </multiselect>
                </div>
                <div class="col-md-12 mt-2">
                    <label class="fw-bold mb-1">
                        Daerah
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <textarea
                        cols="30"
                        rows="2"
                        class="form-control"
                        placeholder="Masukkan daerah"
                        v-model="req.address"
                    ></textarea>
                </div>
                <div class="col-md-12 mt-3">
                    <label class="fw-bold mb-1"> Dimensi Barang </label>
                    <div class="row">
                        <div class="col-md-4">
                            <label class="fw-bold mb-1"> Panjang </label>
                            <div class="input-group mb-0">
                                <input
                                    type="text"
                                    class="form-control form-format"
                                    placeholder="Panjang"
                                    v-model="req.length"
                                    @input="inputNumber()"
                                />
                                <span class="input-group-text">Cm</span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label class="fw-bold mb-1"> Lebar </label>
                            <div class="input-group mb-0">
                                <input
                                    type="text"
                                    class="form-control form-format"
                                    placeholder="Lebar"
                                    v-model="req.width"
                                    @input="inputNumber()"
                                />
                                <span class="input-group-text">Cm</span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label class="fw-bold mb-1"> Tinggi </label>
                            <div class="input-group mb-0">
                                <input
                                    type="text"
                                    class="form-control form-format"
                                    placeholder="Tinggi"
                                    v-model="req.height"
                                    @input="inputNumber()"
                                />
                                <span class="input-group-text">Cm</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-1">
                        Berat
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <div class="input-group mb-0">
                        <input
                            type="text"
                            class="form-control form-format"
                            placeholder="Berat"
                            v-model="req.weight"
                            @input="inputNumber()"
                        />
                        <span class="input-group-text">Kg</span>
                    </div>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-1">
                        Harga Pengiriman
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <div class="input-group mb-0">
                        <span class="input-group-text">Rp</span>
                        <money
                            v-model="req.price"
                            v-bind="price_format"
                            class="form-control form-phone"
                            placeholder="Masukkan harga pengiriman"
                        >
                        </money>
                    </div>
                </div>
                <div class="col-md-12 mt-3">
                    <label class="fw-bold mb-1">
                        Deskripsi
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <textarea
                        cols="30"
                        rows="2"
                        class="form-control"
                        placeholder="Isi deskripsi"
                        v-model="req.prediction_description"
                    ></textarea>
                </div>
            </div>
            <div class="d-flex mt-5">
                <div class="ms-auto">
                    <div class="d-flex">
                        <a
                            href="javascript:void(0)"
                            class="btn btn-outline-yellow px-5 btn-lg me-3"
                        >
                            Batal
                        </a>
                        <template
                            v-if="
                                req.is_prediction == true ||
                                req.is_prediction == null
                            "
                        >
                            <button
                                type="button"
                                :disabled="
                                    req.is_prediction == null ||
                                    (req.is_prediction == true &&
                                        req.service == '') ||
                                    req.regency == null ||
                                    req.destination == null ||
                                    req.address == '' ||
                                    req.weight == '' ||
                                    req.price == '' ||
                                    req.prediction_description == ''
                                "
                                class="btn btn-primary px-5 btn-lg"
                                @click="storeConfirmation()"
                            >
                                Simpan
                            </button>
                        </template>
                        <template v-if="req.is_prediction == false">
                            <button
                                type="button"
                                class="btn btn-primary px-5 btn-lg"
                                @click="storeConfirmation()"
                            >
                                Simpan
                            </button>
                        </template>
                    </div>
                </div>
            </div>
        </div>

        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalConfirmStore"></div>
            <div class="modal-body-chat vsm" v-if="modalConfirmStore">
                <div class="mt-4 text-center">
                    <img src="../../assets/info-yellow.png" width="80" alt="" />
                    <h4 class="mb-0 fw-bold mt-4">
                        Data Aktivitas Follow Up akan disimpan
                    </h4>
                    <div class="text-gray-dashboard">
                        Pastikan data yang diisi sudah benar
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalConfirmStore = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Batal
                        </a>
                    </div>
                    <div class="col-md-6 mt-4">
                        <button
                            @click="storeActifity()"
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            :disabled="is_save"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="is_save"
                            ></span>
                            Ya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalConfirmStorePerdiction"></div>
            <div class="modal-body-chat vsm" v-if="modalConfirmStorePerdiction">
                <div class="mt-4 text-center">
                    <img src="../../assets/info-yellow.png" width="80" alt="" />
                    <h4 class="mb-0 fw-bold mt-4">
                        Data Aktivitas Follow Up akan disimpan
                    </h4>
                    <div class="text-gray-dashboard">
                        Pastikan data yang diisi sudah benar
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalConfirmStorePerdiction = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Batal
                        </a>
                    </div>
                    <div class="col-md-6 mt-4">
                        <button
                            @click="storeActifity()"
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            :disabled="is_save"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="is_save"
                            ></span>
                            Ya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">
                        Aktivitas follow up Berhasil disimpan
                    </h4>
                </div>
                <div class="mt-4">
                    <a
                        href="/followup/all"
                        class="btn btn-primary w-100 btn-lg"
                    >
                        Ok
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import Multiselect from "vue-multiselect";
import { Money } from "v-money";

export default {
    components: {
        Multiselect,
        Money,
    },
    props: ["roles"],
    name: "MakeAProspectLeads",
    data() {
        return {
            specialist: null,
            specialists: [],
            req: {
                activity: "",
                specialist_id: null,
                priority: "",
                description_activity: "",
                is_prediction: null,
                service: "",
                regency: null,
                destination: null,
                address: "",
                height: "",
                length: "",
                width: "",
                weight: "",
                price: "",
                prediction_description: "",
            },
            regencies: [],
            destinations: [],
            is_save: false,
            detailLeads: {},
            followup_id: null,
            modalConfirmStore: false,
            modalSuccess: false,
            modalConfirmStorePerdiction: false,
            managerRoles: ["crm-manager"],
            is_loading_data: false,
            price_format: {
                decimal: ",",
                thousands: ".",
                prefix: "",
                suffix: "",
                precision: 0,
                masked: false,
            },
        };
    },
    created() {
        this.getGeo();
        this.getSpecialists();
        this.getDetailLeads();
    },
    computed: {},
    methods: {
        inputNumber() {
            this.req.length = this.req.length.replace(/[^\d]/g, "");
            this.req.width = this.req.width.replace(/[^\d]/g, "");
            this.req.height = this.req.height.replace(/[^\d]/g, "");
            this.req.weight = this.req.weight.replace(/[^\d]/g, "");
        },
        getGeo() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/geo/regency`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.regencies = data;
                    this.destinations = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getSpecialists() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/user`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.specialists = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getDetailLeads() {
            this.is_loading_data = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/leads/detail?id=${this.$route.params.id}`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )

                .then((res) => {
                    this.is_loading_data = false;
                    this.detailLeads = res.data?.data;
                })
                .catch((err) => {
                    this.is_loading_data = false;
                    console.log(err);
                });
        },
        storeConfirmation() {
            if (this.req.is_prediction == false) {
                this.modalConfirmStore = true;
            }
            if (this.req.is_prediction == true) {
                this.modalConfirmStorePerdiction = true;
            }
        },
        storeActifity() {
            this.is_save = true;

            var data = {
                lead_id: this.$route.params.id,
                activity: this.req.activity,
                specialist_id: this.specialist?.id,
                priority: this.req.priority,
                description_activity: this.req.description_activity,
            };

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/followup/store`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.followup_id = res.data.data.prospect_id;
                    this.is_save = false;
                    if (this.req.is_prediction == true) {
                        this.storePrediction();
                    }
                    if (this.req.is_prediction == false) {
                        this.modalConfirmStore = false;
                        this.modalSuccess = true;
                    }
                })
                .catch((err) => {
                    this.is_save = false;
                    if (err.response.data.data == null) {
                        this.$toast.error(err.response.data.message, {
                            position: "top-right",
                            timeout: 2000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: false,
                            icon: true,
                            rtl: false,
                        });
                    } else {
                        this.$toast.error(err.response.data.data.message, {
                            position: "top-right",
                            timeout: 2000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: false,
                            icon: true,
                            rtl: false,
                        });
                    }
                });
        },
        storePrediction() {
            var data = {
                followup_id: this.followup_id,
                service: this.req.service,
                origin_regency_id: this.req?.regency?.id,
                destination_regency_id: this.req?.destination?.id,
                address: this.req.address,
                length: parseFloat(this.req.length),
                width: parseFloat(this.req.width),
                height: parseFloat(this.req.height),
                weight: parseFloat(this.req.weight),
                price: parseFloat(this.req.price),
                description: this.req.prediction_description,
            };

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/predictions/store`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    this.modalSuccess = true;
                    this.modalConfirmStorePerdiction = false;
                })
                .catch((err) => {
                    if (err.response.data.data == null) {
                        this.$toast.error(err.response.data.message, {
                            position: "top-right",
                            timeout: 2000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: false,
                            icon: true,
                            rtl: false,
                        });
                    } else {
                        this.$toast.error(err.response.data.data.message, {
                            position: "top-right",
                            timeout: 2000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: false,
                            icon: true,
                            rtl: false,
                        });
                    }
                });
        },
        isRole(value) {
            let result = value.some((el) => this.roles.includes(el));
            return result;
        },
    },
};
</script>

<style scoped></style>
