<template>
    <div class="padding-container">
        <div class="box mt-4 m-mt-0">
            <div class="mb-3">
                <h3 class="fw-bold capitalize">
                    Daftar Follow Up -
                    <template v-if="prospect_type == ''">Semua</template
                    ><template v-else>{{
                        prospect_type.split("_").join(" ")
                    }}</template>
                </h3>
            </div>
            <div class="d-flex align-items-center no-flex mt-4">
                <div class="me-4 m-mx-0 m-mt-2">
                    <div class="position-relative">
                        <div class="input-group">
                            <input
                                type="text"
                                class="form-control form-control-lg pl-search w-search-leads border-search"
                                placeholder="Cari nama customer"
                                v-model="search"
                            />
                            <button
                                class="btn btn-primary px-4"
                                type="button"
                                @click="searchData()"
                            >
                                Cari
                            </button>
                        </div>
                        <span class="search-icon left">
                            <i class="pi pi-search"></i>
                        </span>
                    </div>
                </div>
                <div class="me-auto m-mt-1">
                    <button
                        class="btn btn-outline-yellow btn-filter px-4"
                        @click="isFilter = !isFilter"
                    >
                        <img
                            src="../../assets/dashboard/filter.png"
                            class="me-1"
                            width="18"
                            alt=""
                        />
                        Filter
                    </button>
                </div>
            </div>
            <div class="box mt-3 position-relative" v-if="isFilter">
                <div class="row">
                    <div
                        class="mb-2"
                        v-bind:class="{
                            'col-md-6': !isRole(managerRoles),
                            'col-md-4': isRole(managerRoles),
                        }"
                    >
                        <label class="mb-1 fw-bold">Jenis Layanan</label>
                        <multiselect
                            v-model="service"
                            :options="services"
                            :show-labels="false"
                            label="name"
                            track-by="id"
                            placeholder="Pilih jenis layanan"
                            class="multi-form-custom"
                        >
                        </multiselect>
                    </div>
                    <div
                        class="mb-2"
                        v-bind:class="{
                            'col-md-6': !isRole(managerRoles),
                            'col-md-4': isRole(managerRoles),
                        }"
                    >
                        <label class="mb-1 fw-bold">Kategori</label>
                        <multiselect
                            v-model="category"
                            :options="categories"
                            :show-labels="false"
                            label="label"
                            track-by="type"
                            placeholder="Pilih kategori"
                            class="multi-form-custom"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-4 mb-2" v-show="isRole(managerRoles)">
                        <label class="mb-1 fw-bold">CRM Specialist</label>
                        <multiselect
                            v-model="specialist"
                            :options="specialists"
                            :show-labels="false"
                            label="name"
                            track-by="id"
                            placeholder="Pilih CRM Specialist"
                            class="multi-form-custom"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-2">
                        <label class="mb-1 fw-bold"></label>
                        <button
                            class="btn btn-outline-yellow px-5 w-100"
                            @click="clearFilter()"
                            :disabled="
                                branch == null &&
                                service == null &&
                                specialist == null &&
                                category == null
                            "
                        >
                            Clear
                        </button>
                    </div>
                    <div class="col-md-2">
                        <button
                            class="btn btn-green px-5 w-100"
                            :disabled="
                                branch == null &&
                                service == null &&
                                specialist == null &&
                                category == null
                            "
                            @click="filter()"
                        >
                            Filter
                        </button>
                    </div>
                </div>
            </div>
            <div class="d-flex align-items-center mt-4">
                <div>
                    Menampilkan
                    <span class="fw-bold">1-{{ limit }}</span> Data dari
                    <span class="fw-bold">{{ data.total_data }}</span>
                    Data
                </div>
                <div class="ms-auto">
                    <div class="d-flex align-items-center">
                        <div class="me-2">Baris per halaman</div>
                        <select
                            class="form-control form-select w-select"
                            v-model="limit"
                            @change="getLists()"
                        >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="is_load_data">
                <ListLoader />
            </div>
            <div v-else>
                <div class="table-responsive-custom">
                    <table class="table table-bordered mt-4">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">No</th>
                                <th class="text-center" scope="col">
                                    <div class="d-flex align-items-center">
                                        Tanggal Follow Up
                                        <div class="ms-auto">
                                            <a
                                                href="javascript:void(0)"
                                                class="dropdown-toggle ms-2"
                                                id="statusRefund"
                                                data-bs-toggle="dropdown"
                                                data-bs-auto-close="true"
                                                aria-expanded="false"
                                            >
                                                <img
                                                    src="../../assets/dashboard/sort.png"
                                                    width="9"
                                                    alt=""
                                                />
                                            </a>
                                            <ul
                                                class="dropdown-menu"
                                                aria-labelledby="statusRefund"
                                            >
                                                <li>
                                                    <a
                                                        class="dropdown-item"
                                                        href="javascript:void(0)"
                                                        @click="sortBy('asc')"
                                                    >
                                                        Terbaru
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        class="dropdown-item"
                                                        href="javascript:void(0)"
                                                        @click="sortBy('desc')"
                                                    >
                                                        Terlama
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </th>
                                <th class="text-center" scope="col">
                                    Nama Customer
                                </th>
                                <th class="text-center" scope="col">
                                    Aktivitas Terakhir
                                </th>
                                <th class="text-center" scope="col">
                                    Jenis Layanan
                                </th>
                                <th class="text-center" scope="col">
                                    Kategori
                                </th>
                                <th class="text-center" scope="col">
                                    Prediksi
                                </th>
                                <th class="text-center" scope="col">
                                    Cust. Lifetime Value
                                </th>
                                <th
                                    class="text-center"
                                    scope="col"
                                    v-show="isRole(managerRoles)"
                                >
                                    CRM Specialist
                                </th>
                                <th class="text-center" scope="col">Result</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(data, index) in data.list_data"
                                :key="index"
                            >
                                <td class="text-center">{{ index + 1 }}</td>
                                <td class="text-center">
                                    {{
                                        data.created_at ? data.created_at : "-"
                                    }}
                                </td>
                                <td class="">
                                    <a
                                        class="text-link"
                                        :href="
                                            `/followup/detail?lead_id=` +
                                            data.lead_id +
                                            `&followup_id=` +
                                            data.followup_id
                                        "
                                    >
                                        {{
                                            data.customer_name
                                                ? data.customer_name
                                                : "-"
                                        }}
                                    </a>
                                </td>
                                <td class="capitalize">
                                    {{
                                        data.last_activity
                                            ? data.last_activity
                                            : "-"
                                    }}
                                </td>
                                <td class="capitalize">
                                    {{ data.service_type.split("_").join(" ") }}
                                </td>
                                <td class="capitalize text-center">
                                    <span
                                        class="tag me-0"
                                        v-bind:class="{
                                            'yellow-cro':
                                                data.category == 'New Customer',
                                            'blue-cro':
                                                data.category == 'New Order',
                                            'green-cro':
                                                data.category == 'Repeat Order',
                                        }"
                                    >
                                        {{
                                            data.category ? data.category : "-"
                                        }}
                                    </span>
                                </td>
                                <td class="capitalize">
                                    <div class="pull-right">
                                        <a
                                            :href="
                                                `/prediction/edit/` +
                                                data.followup_id
                                            "
                                        >
                                            <u>
                                                <template
                                                    v-if="data.prediction == 0"
                                                >
                                                    Rp0
                                                </template>
                                                <template v-else>
                                                    {{
                                                        currency(
                                                            data.prediction
                                                        )
                                                    }}
                                                </template>
                                                <i
                                                    class="pi pi-pencil ms-1"
                                                ></i>
                                            </u>
                                        </a>
                                    </div>
                                </td>
                                <td class="capitalize">
                                    <div class="pull-right">
                                        {{ currency(data.lifetime_vallue) }}
                                    </div>
                                </td>
                                <td
                                    class="capitalize"
                                    v-show="isRole(managerRoles)"
                                >
                                    {{
                                        data.crm_specialist
                                            ? data.crm_specialist
                                            : "-"
                                    }}
                                </td>
                                <td class="text-center">
                                    <div class="dropdown">
                                        <a
                                            class="dropdown-toggle"
                                            role="button"
                                            href="javascript:void(0)"
                                            :id="'dropdownMenuButton' + index"
                                            data-bs-toggle="dropdown"
                                            data-bs-auto-close="true"
                                            aria-expanded="false"
                                        >
                                            <img
                                                src="../../assets/ellips.png"
                                                width="19"
                                                alt=""
                                            />
                                        </a>
                                        <ul
                                            class="dropdown-menu"
                                            :aria-labelledby="
                                                'dropdownMenuButton' + index
                                            "
                                        >
                                            <li>
                                                <router-link
                                                    class="dropdown-item"
                                                    :to="{
                                                        name: 'crm-prospect-deal',
                                                        params: {
                                                            id: data.followup_id,
                                                        },
                                                    }"
                                                    >Deal</router-link
                                                >
                                            </li>
                                            <li>
                                                <router-link
                                                    class="dropdown-item"
                                                    :to="{
                                                        name: 'crm-prospect-lost',
                                                        params: {
                                                            id: data.followup_id,
                                                            type: 'lost',
                                                        },
                                                    }"
                                                    >Lost</router-link
                                                >
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="text-center mt-5" v-if="data.list_data == 0">
                    <img
                        src="../../assets/no-data-green.png"
                        width="100"
                        alt=""
                    />
                    <div class="mt-4 mb-4 text-gray">
                        Belum ada daftar follow up
                    </div>
                </div>
            </div>
            <div class="d-flex mt-4">
                <div class="ms-auto">
                    <b-pagination-nav
                        v-if="data.list_data.length > 0"
                        v-model="currentPage"
                        :number-of-pages="totalPage"
                        base-url="#"
                        first-number
                        align="right"
                        @input="getLists()"
                    ></b-pagination-nav>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Api from "../../api/Api";
import ListLoader from "../../components/ListLoader.vue";
import Multiselect from "vue-multiselect";

export default {
    components: {
        ListLoader,
        Multiselect,
    },
    props: {
        items: {
            type: Object,
            default: () => {},
        },
        roles: [],
    },
    data() {
        return {
            data: {
                list_data: [],
            },
            search: "",
            is_load_data: false,
            currentPage: 0,
            totalPage: 0,
            limit: 10,
            prospect_type: "",
            isFilter: false,
            branch: null,
            branchs: [],
            service: null,
            services: [
                {
                    name: "Pengiriman Barang",
                    id: "pengiriman_barang",
                },
                {
                    name: "Pengiriman Truk",
                    id: "pengiriman_truk",
                },
                {
                    name: "Pengiriman Mobil",
                    id: "pengiriman_mobil",
                },
                {
                    name: "Pengiriman Via Kontainer",
                    id: "pengiriman_via_kontainer",
                },
            ],
            specialist: null,
            specialists: [],
            category: null,
            categories: [
                {
                    type: "new_customer",
                    label: "New Customer",
                },
                {
                    type: "new_order",
                    label: "New Order",
                },
                {
                    type: "repeat_order",
                    label: "Repeat Order",
                },
            ],
            managerRoles: ["crm-manager"],
            sort_at: "asc",
        };
    },
    created() {
        this.prospect_type = this.items.name;
        this.getLists();
        this.getSpecialists();
    },
    methods: {
        getLists() {
            this.is_load_data = true;
            let payload = {
                search: this.search,
                limit: this.limit,
                filter: this.items.prospect_type,
                page:
                    this.currentPage == 0 || this.currentPage == null
                        ? 0
                        : this.currentPage - 0,
            };
            if (this.sort_at) {
                payload.sort_at = this.sort_at;
            }
            if (this.service) {
                payload.service = this.service?.id;
            }
            if (this.category) {
                payload.category = this.category?.type;
            }
            if (this.specialist) {
                payload.specialist_id = this.specialist.id;
            }
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/followup/lists`, {
                params: payload,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.data = res.data.data;
                    this.totalPage = res.data.data.total_page;
                    this.is_load_data = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_load_data = false;
                });
        },
        searchData() {
            this.getLists();
            this.currentPage = 0;
        },
        isRole(value) {
            let result = value.some((el) => this.roles.includes(el));
            return result;
        },
        sortBy(value) {
            this.sort_at = value;
            this.getLists();
        },
        getSpecialists() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/user`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.specialists = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        filter() {
            this.getLists();
        },
        clearFilter() {
            this.service = null;
            this.category = null;
            this.specialist = null;
            this.getLists();
        },
    },
    computed: {},
};
</script>
<style lang="scss" scoped>
.bt-size {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 159px !important;
    height: 44px !important;
    border-radius: 8px;
}
.search-icon-left {
    top: 11px;
    left: 10px;
}
.excel {
    width: 20px !important;
    height: 19.05px !important;
}
th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
.button {
    display: flex;
    border-radius: 12px;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 100%;
    height: 44px;
    &-save {
        background: #48a2d4;
        border: 1px solid #48a2d4;
        color: white;
    }
    &-save:is(:hover, :focus) {
        background: #176f9e;
    }
    &-delete {
        background: #e60013;
        border: 1px solid #e60013;
        color: white;
    }
    &-delete:is(:hover, :focus) {
        background: #e94b58;
    }
    &-cancel {
        background: #ffffff;
        border: 1px solid #e94b58;
        color: #e94b58;
    }
    &-cancel:is(:hover, :focus) {
        background: #e94b58;
        border: 1px solid #ffffff;
        color: #ffffff;
    }
}
.pill {
    display: inline-block;
    width: 110px;
    height: 24px;
    border-radius: 40px;
    text-align: center;
    color: white;
    &.red {
        background-color: #e60013;
        border: 1px solid #e60013;
        outline: 1px solid #e60013;
    }

    &.green {
        background-color: #3d8824;
        border: 1px solid #3d8824;
        outline: 1px solid #3d8824;
    }
}
.text-link {
    text-decoration: underline;
    color: #24516a;
}
.pill {
    display: inline-block;
    width: 110px;
    height: 24px;
    border-radius: 40px;
    text-align: center;
    color: white !important;
    &.gray {
        color: #000 !important;
        background-color: #f4f6f8;
        border: 1px solid #f4f6f8;
        outline: 1px solid #f4f6f8;
    }
    &.red {
        background-color: #e60013;
        border: 1px solid #e60013;
        outline: 1px solid #e60013;
    }

    &.green {
        background-color: #3d8824;
        border: 1px solid #3d8824;
        outline: 1px solid #3d8824;
    }
    &.yellow {
        background-color: #fb9727;
        border: 1px solid #fb9727;
        outline: 1px solid #fb9727;
    }
    &.blue {
        background-color: #48a2d4;
        border: 1px solid #48a2d4;
        outline: 1px solid #48a2d4;
    }
}
.prediction-color {
    color: #24516a;
}
</style>
