<template>
    <div class="padding-container">
        <div class="content-load" v-if="is_loading_data"></div>
        <div id="loader" v-if="is_loading_data"></div>
        <notifications group="foo" position="top right" />
        <div class="fw-bold">
            <a
                href="javascript:void(0)"
                @click="$router.go(-1)"
                class="text-gray-dashboard size-16"
            >
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </a>
        </div>
        <div class="box mt-4">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold mb-0">Prediksi</h3>
            </div>
            <div class="row">
                <div class="col-md-12 mt-3">
                    <label class="fw-bold mb-1">
                        Jenis Layanan
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <select
                        class="form-control form-select"
                        v-model="req.service"
                    >
                        <option value="">Pilih jenis layanan</option>
                        <option value="pengiriman_barang">
                            Pengiriman Barang
                        </option>
                        <option value="pengiriman_motor">
                            Pengiriman Motor
                        </option>
                        <option value="pengiriman_mobil">
                            Pengiriman Mobil
                        </option>
                        <option value="pengiriman_truk">Pengiriman Truk</option>
                        <option value="pengiriman_via_kontainer">
                            Pengiriman Via Kontainer
                        </option>
                    </select>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-1">
                        Kota/Kab. Asal
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <multiselect
                        v-model="regency"
                        :options="regencies"
                        label="name"
                        track-by="id"
                        placeholder="Cari kota/kabupaten"
                        class="multi-form-custom"
                        :show-labels="false"
                    >
                    </multiselect>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-1">
                        Kota/Kab. Tujuan
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <multiselect
                        v-model="destination"
                        :options="destinations"
                        label="name"
                        track-by="id"
                        placeholder="Cari kota/kabupaten"
                        class="multi-form-custom"
                        :show-labels="false"
                    >
                    </multiselect>
                </div>
                <div class="col-md-12 mt-2">
                    <label class="fw-bold mb-1">
                        Daerah
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <textarea
                        cols="30"
                        rows="2"
                        class="form-control"
                        placeholder="Masukkan daerah"
                        v-model="req.address"
                    ></textarea>
                </div>
                <div class="col-md-12 mt-3">
                    <label class="fw-bold mb-1"> Dimensi Barang </label>
                    <div class="row">
                        <div class="col-md-4">
                            <label class="fw-bold mb-1"> Panjang </label>
                            <div class="input-group mb-0">
                                <money
                                    v-model="req.length"
                                    v-bind="price_format"
                                    class="form-control form-format"
                                    placeholder="Panjang"
                                >
                                </money>
                                <span class="input-group-text">Cm</span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label class="fw-bold mb-1"> Lebar </label>
                            <div class="input-group mb-0">
                                <money
                                    v-model="req.width"
                                    v-bind="price_format"
                                    class="form-control form-format"
                                    placeholder="Lebar"
                                >
                                </money>
                                <span class="input-group-text">Cm</span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label class="fw-bold mb-1"> Tinggi </label>
                            <div class="input-group mb-0">
                                <money
                                    v-model="req.height"
                                    v-bind="price_format"
                                    class="form-control form-format"
                                    placeholder="Tinggi"
                                >
                                </money>
                                <span class="input-group-text">Cm</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-1">
                        Berat
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <div class="input-group mb-0">
                        <money
                            v-model="req.weight"
                            v-bind="price_format"
                            class="form-control form-format"
                            placeholder="Berat"
                        >
                        </money>
                        <span class="input-group-text">Kg</span>
                    </div>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-1">
                        Harga Pengiriman
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <div class="input-group mb-0">
                        <span class="input-group-text">Rp</span>
                        <money
                            v-model="req.price"
                            v-bind="price_format"
                            class="form-control form-phone"
                            placeholder="Masukkan harga pengiriman"
                        >
                        </money>
                    </div>
                </div>
                <div class="col-md-12 mt-3">
                    <label class="fw-bold mb-1">
                        Deskripsi
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <textarea
                        cols="30"
                        rows="2"
                        class="form-control"
                        placeholder="Isi deskripsi"
                        v-model="req.description"
                    ></textarea>
                </div>
            </div>
            <div class="d-flex mt-5">
                <div class="ms-auto">
                    <div class="d-flex">
                        <a
                            href="javascript:void(0)"
                            @click="$router.go(-1)"
                            class="btn btn-outline-yellow px-5 btn-lg me-3"
                        >
                            Batal
                        </a>
                        <button
                            type="button"
                            :disabled="
                                req.service == '' ||
                                regency == null ||
                                destination == null ||
                                req.address == '' ||
                                req.weight == '' ||
                                req.price == '' ||
                                req.description == ''
                            "
                            class="btn btn-primary px-5 btn-lg"
                            @click="storeConfirmation()"
                        >
                            Simpan
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalConfirmStorePerdiction"></div>
            <div class="modal-body-chat vsm" v-if="modalConfirmStorePerdiction">
                <div class="mt-4 text-center">
                    <img src="../../assets/info-yellow.png" width="80" alt="" />
                    <h4 class="mb-0 fw-bold mt-4">
                        Informasi Prediksi Akan Diperbaharui
                    </h4>
                    <div class="text-gray-dashboard">
                        Pastikan data yang diisi sudah benar
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalConfirmStorePerdiction = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Batal
                        </a>
                    </div>
                    <div class="col-md-6 mt-4">
                        <button
                            @click="storePrediction()"
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            :disabled="is_save"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="is_save"
                            ></span>
                            Ok
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">
                        Informasi Prediksi Berhasil Diperbaharui
                    </h4>
                </div>
                <div class="mt-4">
                    <a
                        href="/followup/all"
                        class="btn btn-primary w-100 btn-lg"
                    >
                        Tutup
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import Multiselect from "vue-multiselect";
import { Money } from "v-money";
import $ from "jquery";

export default {
    components: {
        Multiselect,
        Money,
    },
    name: "PredictionEdit",
    data() {
        return {
            specialist: null,
            specialists: [],
            regency: {
                id: "",
                name: "",
            },
            destination: {
                id: "",
                name: "",
            },
            req: {
                activity: "",
                specialist_id: null,
                priority: "",
                description_activity: "",
                is_prediction: null,
                service: "",
                address: "",
                height: "",
                length: "",
                width: "",
                weight: "",
                price: "",
                description: "",
            },
            regencies: [],
            destinations: [],
            is_save: false,
            followup_id: null,
            modalConfirmStore: false,
            modalSuccess: false,
            modalConfirmStorePerdiction: false,
            price_format: {
                decimal: ",",
                thousands: ".",
                prefix: "",
                suffix: "",
                precision: 0,
                masked: false,
            },
            is_loading_data: false,
        };
    },
    created() {
        this.getGeo();
        this.getSpecialists();
    },
    computed: {},
    mounted() {
        $(document).ready(function () {
            //called when key is pressed in textbox
            $("#quantity").keypress(function (e) {
                //if the letter is not digit then display error and don't type anything
                if (
                    e.which != 8 &&
                    e.which != 0 &&
                    (e.which < 48 || e.which > 57)
                ) {
                    //display error message
                    $("#errmsg").html("Numbers Only").show().fadeOut("slow");
                    return false;
                }
            });
        });
    },
    methods: {
        inputNumber() {
            this.req.length = this.req.length.replace(/[^\d]/g, "");
            this.req.width = this.req.width.replace(/[^\d]/g, "");
            this.req.height = this.req.height.replace(/[^\d]/g, "");
            this.req.weight = this.req.weight.replace(/[^\d]/g, "");
        },
        getGeo() {
            this.is_loading_data = true;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/geo/regency`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.regencies = data;
                    this.destinations = data;
                    this.is_loading_data = false;
                    this.getDetailPrediction();
                })
                .catch((err) => {
                    this.is_loading_data = false;
                    console.log(err);
                });
        },
        getSpecialists() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/user`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.specialists = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getDetailPrediction() {
            this.is_loading_data = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/predictions/detail?followup_id=${this.$route.params.id}`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )

                .then((res) => {
                    if (res.data.data != null) {
                        this.req = res.data.data;
                        this.req.length = res?.data?.data?.dimension?.length;
                        this.req.width = res?.data?.data?.dimension?.width;
                        this.req.height = res?.data?.data?.dimension?.height;
                        this.regency.name = res?.data?.data?.origin;
                        this.regency.id = res.data.data.origin_id;
                        this.destination.name = res?.data?.data?.dest;
                        this.destination.id = res?.data?.data?.dest_id;
                    } else {
                        this.regency = null;
                        this.destination = null;
                    }
                    this.is_loading_data = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_loading_data = false;
                });
        },
        storeConfirmation() {
            this.modalConfirmStorePerdiction = true;
        },
        storePrediction() {
            this.is_save = true;
            var data = {
                followup_id: this.$route.params.id,
                service: this.req.service,
                origin_regency_id: this.regency?.id,
                destination_regency_id: this.destination?.id,
                address: this.req.address,
                length: parseFloat(this.req.length),
                width: parseFloat(this.req.width),
                height: parseFloat(this.req.height),
                weight: parseFloat(this.req.weight),
                price: parseFloat(this.req.price),
                description: this.req.description,
            };

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/predictions/store`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    this.modalSuccess = true;
                    this.modalConfirmStorePerdiction = false;
                    this.is_save = false;
                })
                .catch((err) => {
                    if (err.response.data.data == null) {
                        this.$toast.error(err.response.data.message, {
                            position: "top-right",
                            timeout: 2000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: false,
                            icon: true,
                            rtl: false,
                        });
                    } else {
                        this.$toast.error(err.response.data.data.message, {
                            position: "top-right",
                            timeout: 2000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: false,
                            icon: true,
                            rtl: false,
                        });
                    }
                    this.is_save = false;
                });
        },
    },
};
</script>

<style scoped></style>
