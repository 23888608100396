<template>
    <div class="padding-container">
        <notifications group="foo" position="top right" />
        <div class="row">
            <div class="col-md-3">
                <div class="box">
                    <h5 class="fw-bold text-center">Upload Avatar</h5>
                    <div class="mt-2 size-12 text-center">
                        Klik icon untuk mengupload dan mengupdate foto anda.
                    </div>
                    <div
                        class="image-view-avatar mt-4"
                        :style="{
                            'background-image':
                                'url(' + req.partner.avatar + ')',
                        }"
                    >
                        <div class="mt-4">
                            <label
                                for="avatar"
                                class="upload-button single-image"
                            >
                                <i class="fa fa-upload"></i>
                                <input
                                    type="file"
                                    id="avatar"
                                    @change="avatarUpload()"
                                />
                            </label>
                        </div>
                    </div>
                    <div class="mt-3">
                        <button
                            @click="storeAvatar()"
                            class="btn btn-primary w-100"
                            :disabled="is_changing_avatar == false"
                        >
                            Update Avatar
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-md-9">
                <div class="box">
                    <div class="row">
                        <div class="col-md-6">
                            <label for="" class="mb-2 fw-bold"
                                >Nama Lengkap</label
                            >
                            <input
                                type="text"
                                class="form-control"
                                placeholder="Masukkan nama lengkap"
                                v-model="req.partner.nama_lengkap"
                            />
                        </div>
                        <div class="col-md-6">
                            <label for="" class="mb-2 fw-bold"
                                >Nomor Induk Berusaha</label
                            >
                            <input
                                type="number"
                                class="form-control"
                                placeholder="Masukkan nomor induk"
                                v-model="req.partner.nomor_induk_perusahaan"
                            />
                        </div>
                        <div class="col-md-6 mt-3">
                            <label for="" class="mb-2 fw-bold">Email</label>
                            <input
                                type="email"
                                class="form-control"
                                placeholder="Masukkan email"
                                v-model="req.partner.email"
                            />
                        </div>
                        <div class="col-md-6 mt-3">
                            <label for="" class="mb-2 fw-bold"
                                >Tahun Berdiri</label
                            >
                            <input
                                type="text"
                                class="form-control"
                                placeholder="Masukkan tahun"
                                v-model="req.partner.tahun_berdiri"
                            />
                        </div>
                        <div class="col-md-12 mt-3">
                            <label for="" class="mb-2 fw-bold"
                                >Alamat Mitra</label
                            >
                            <textarea
                                cols="30"
                                rows="5"
                                class="form-control"
                                placeholder="Masukkan alamat"
                                v-model="req.partner.alamat_mitra"
                            ></textarea>
                        </div>
                        <div class="col-md-12 mt-3">
                            <label for="" class="mb-2 fw-bold"
                                >Alamat Mitra Digoogle Maps</label
                            >
                            <input
                                type="text"
                                class="form-control"
                                placeholder="Masukkan alamat"
                                v-model="req.partner.alamat_mitra_google_map"
                            />
                        </div>
                        <div class="col-md-6 mt-3">
                            <label for="" class="mb-2 fw-bold"
                                >Nomor Telepon</label
                            >
                            <input
                                type="number"
                                class="form-control"
                                placeholder="Masukkan nomor telepon"
                                v-model="req.partner.phone_number"
                            />
                        </div>
                        <div class="col-md-6 mt-3">
                            <label for="" class="mb-2 fw-bold"
                                >Terakhir Update</label
                            >
                            <input
                                type="text"
                                class="form-control"
                                placeholder="Masukkan tanggal"
                                v-model="req.partner.terakhir_diupdate"
                            />
                        </div>
                        <div class="col-md-12 mt-3">
                            <label for="" class="mb-2 fw-bold"
                                >Kota/Kabupaten/Kecamatan</label
                            >
                            <multiselect
                                v-model="destination"
                                :options="geoLists"
                                label="label"
                                :loading="is_loading"
                                track-by="sub_district_id"
                                placeholder="Pilih kota/kabupaten/kecamatan"
                                @search-change="searchGeoList"
                                :show-labels="false"
                            >
                            </multiselect>
                        </div>
                        <div class="col-md-12 mt-4">
                            <h5 class="mb-3 fw-bold">Masukan Bank Anda</h5>
                        </div>
                        <div class="col-md-4">
                            <label for="" class="mb-2 fw-bold"
                                >Nama Pemilik Rekening</label
                            >
                            <input
                                type="text"
                                class="form-control"
                                placeholder="masukkan nama"
                                v-model="req.partner.nama_pemilik_rekening"
                            />
                        </div>
                        <div class="col-md-4">
                            <label for="" class="mb-2 fw-bold"
                                >Pilih Bank</label
                            >
                            <select
                                class="form-control form-select"
                                v-model="req.partner.bank_id"
                            >
                                <option value="">Pilih bank anda</option>
                                <template v-for="bank in req.banks">
                                    <option :value="bank.id" :key="bank.id">
                                        {{ bank.name }}
                                    </option>
                                </template>
                            </select>
                        </div>
                        <div class="col-md-4">
                            <label for="" class="mb-2 fw-bold"
                                >Nomor Rekening</label
                            >
                            <input
                                type="number"
                                class="form-control"
                                placeholder="masukkan nomor rekening"
                                v-model="req.partner.nomor_rekening"
                            />
                        </div>
                        <div class="col-md-6 mt-3">
                            <label for="" class="mb-2 fw-bold">Foto NPWP</label>
                            <div
                                class="image-view-1"
                                :style="{
                                    'background-image':
                                        'url(' + req.partner.foto_npwp + ')',
                                }"
                            >
                                <div class="mt-4 ml-4">
                                    <label
                                        for="npwp"
                                        class="upload-button single-image"
                                    >
                                        <i class="fa fa-upload"></i>
                                        <input
                                            type="file"
                                            id="npwp"
                                            @change="npwpUpload()"
                                        />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mt-3">
                            <label for="" class="mb-2 fw-bold">Foto KTP</label>
                            <div
                                class="image-view-2"
                                :style="{
                                    'background-image':
                                        'url(' + req.partner.foto_ktp + ')',
                                }"
                            >
                                <div class="mt-4 ml-4">
                                    <label
                                        for="ktp"
                                        class="upload-button single-image"
                                    >
                                        <i class="fa fa-upload"></i>
                                        <input
                                            type="file"
                                            id="ktp"
                                            @change="ktpUpload()"
                                        />
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-4">
                    <button
                        class="btn btn-primary btn-lg px-5"
                        @click="storeDataProfile()"
                    >
                        Simpan Perubahan
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from "jquery";
import Api from "../../api/Api";
import "vue-search-select/dist/VueSearchSelect.css";
import Multiselect from "vue-multiselect";

export default {
    components: {
        Multiselect,
    },
    name: "Profile",
    data() {
        return {
            req: {
                partner: {
                    bank_id: "",
                    bank: "",
                },
            },
            destination: null,
            is_changing_npwp: false,
            is_changing_ktp: false,
            is_changing_avatar: false,
            geoLists: [],
            selected: "",
            searchTextGeo: "",
            is_loading: false,
            user: "",
        };
    },
    created() {
        this.getUserLogin();
    },
    mounted() {},
    methods: {
        getUserLogin() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/account/user/me`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.user = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        storeAvatar() {
            var file = $("#avatar")[0].files[0];
            var data = new FormData();

            if (this.is_changing_avatar) {
                data.append("avatar", file);
            }

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/carrier/partner/profile/avatar`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.is_changing_avatar = false;
                    this.$notify({
                        group: "foo",
                        type: "success",
                        title: "Success",
                        text: "Foto terupdate",
                    });
                    this.getProfile();
                    console.log(res);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        npwpUpload() {
            var npwp = new FileReader();
            npwp.onload = function (e) {
                $(".image-view-1").css(
                    "background-image",
                    "url(" + e.target.result + ")"
                );
            };
            npwp.readAsDataURL($("#npwp")[0].files[0]);
            this.is_changing_npwp = true;
        },
        ktpUpload() {
            var ktp = new FileReader();
            ktp.onload = function (e) {
                $(".image-view-2").css(
                    "background-image",
                    "url(" + e.target.result + ")"
                );
            };
            ktp.readAsDataURL($("#ktp")[0].files[0]);
            this.is_changing_ktp = true;
        },
        avatarUpload() {
            var avatar = new FileReader();
            avatar.onload = function (e) {
                $(".image-view-avatar").css(
                    "background-image",
                    "url(" + e.target.result + ")"
                );
            };
            avatar.readAsDataURL($("#avatar")[0].files[0]);
            this.is_changing_avatar = true;
        },
        searchGeoList(searchTextGeo) {
            this.searchTextGeo = searchTextGeo;
            this.is_loading = true;
            Api.get(
                `${process.env.VUE_APP_BASE_URL}/geo/list?type=sub_district&search=${searchTextGeo}&page=1&per_page=15`
            )
                .then((res) => {
                    this.geoLists = res.data.data;
                    this.is_loading = false;
                })
                .catch((err) => {
                    this.is_loading = false;
                    console.log(err);
                });
        },

        storeDataProfile() {
            var ktp = $("#ktp")[0].files[0];
            var npwp = $("#npwp")[0].files[0];
            var data = new FormData();

            data.append("title", this.req.partner.nama_lengkap);
            data.append("nip", this.req.partner.nomor_induk_perusahaan);
            data.append("email", this.req.partner.email);
            data.append("year", this.req.partner.tahun_berdiri);
            data.append("alamat_mitra", this.req.partner.alamat_mitra);
            data.append(
                "alamat_mitra_google_map",
                this.req.partner.alamat_mitra_google_map
            );
            data.append("phone_number", this.req.partner.phone_number);
            data.append("account_name", this.req.partner.nama_pemilik_rekening);
            data.append("account_number", this.req.partner.nomor_rekening);
            data.append("bank_id", this.req.partner.bank_id);
            data.append("sub_district_id", this.destination.sub_district_id);
            if (this.is_changing_ktp) {
                data.append("ktp", ktp);
            }
            if (this.is_changing_npwp) {
                data.append("npwp", npwp);
            }

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/carrier/partner/profile`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.is_changing_ktp = false;
                    this.is_changing_npwp = false;
                    this.$notify({
                        group: "foo",
                        type: "success",
                        title: "Success",
                        text: "Data berhasil diupdate",
                    });
                    this.getProfile();
                    console.log(res);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
};
</script>

<style>
.sticky {
    position: fixed;
    width: 250px;
}
</style>
