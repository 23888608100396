<template>
    <component :is="layout" :roles="roles" />
</template>

<script>
import firebase from "firebase/app";
import "firebase/messaging";

export default {
    name: "App",
    computed: {
        layout() {
            let layoutVar = this.$route?.meta
                ? this.$route?.meta.layout
                : "default";
            if (layoutVar === undefined || layoutVar == "undefined") {
                layoutVar = "default";
            }
            return layoutVar + "-layout";
        },
    },
    data() {
        return {
            jwtToken: localStorage.getItem("token"),
            roles: [],
        };
    },

    created() {
        if (this.jwtToken == null) {
            this.$router.push("/");
        }
        // var Notification =
        //     window.Notification ||
        //     window.mozNotification ||
        //     window.webkitNotification;
        // var was_questioned = false;
        // if (Notification.permission == "default") {
        //     was_questioned = true;
        // }
        // Notification.requestPermission(function (permission) {
        //     if (was_questioned) {
        //         console.log("User was asked. New permission is: " + permission);
        //     }
        //     if ("permissions" in navigator) {
        //         navigator.permissions
        //             .query({ name: "notifications" })
        //             .then(function (notificationPerm) {
        //                 notificationPerm.onchange = function () {
        //                     console.log(
        //                         "User decided to change his seettings. New permission: " +
        //                             notificationPerm.state
        //                     );
        //                 };
        //             });
        //     }
        // });
        // try {
        //     firebase
        //         .messaging()
        //         .requestPermission()
        //         .then(() => {
        //             console.log("Notification permission granted");
        //             firebase
        //                 .messaging()
        //                 .getToken()
        //                 .then((token) => {
        //                     this.token = token;
        //                     this.receiveMessage();
        //                 });
        //         })
        //         .catch((err) => {
        //             console.log("Unable to get token ", err);
        //         });
        // } catch (e) {
        //     console.log(e);
        // }
    },
    filters: {
        formatExpTime(expTime) {
            const date = new Date(expTime * 1000);
            return date.toLocaleString();
        },
    },
    methods: {
        receiveMessage() {
            try {
                firebase.messaging().onMessage((payload) => {
                    console.log(payload);
                    this.message = payload.notification.body;
                    this.$notify({
                        group: "foo",
                        type: "success",
                        title: "Pesan Baru",
                        text: payload.notification.body,
                    });
                });
            } catch (e) {
                console.log(e);
            }
        },
    },
};
</script>

<style></style>
