import { render, staticRenderFns } from "./CustomersLeads.vue?vue&type=template&id=0fc7c63e"
import script from "./CustomersLeads.vue?vue&type=script&lang=js"
export * from "./CustomersLeads.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports