<template>
    <div class="padding-container">
        <div class="content-load" v-if="is_loading_data"></div>
        <div id="loader" v-if="is_loading_data"></div>
        <div class="fw-bold">
            <a
                href="javascript:void(0)"
                @click="$router.go(-1)"
                class="text-gray-dashboard size-16"
            >
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </a>
        </div>

        <div class="box mt-4">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold mb-0">Hasil Deal</h3>
            </div>
            <div class="row">
                <div class="col-md-12 mt-3">
                    <label class="fw-bold mb-1">
                        Jenis Layanan
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <input
                        type="text"
                        class="form-control capitalize"
                        placeholder="Masukkan jenis layanan"
                        :value="req?.service"
                        disabled
                    />
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-1">
                        Kota/Kab. Asal
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Cari kota/kabupaten"
                        :value="req?.origin"
                        disabled
                    />
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-1">
                        Kota/Kab. Tujuan
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Cari kota/kabupaten"
                        :value="req?.dest"
                        disabled
                    />
                </div>
                <div class="col-md-12 mt-3">
                    <label class="fw-bold mb-1">
                        Daerah
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <textarea
                        cols="30"
                        rows="2"
                        class="form-control"
                        placeholder="Masukkan daerah"
                        :value="req?.address"
                        disabled
                    ></textarea>
                </div>
                <div class="col-md-12 mt-3">
                    <label class="fw-bold mb-1"> Dimensi Barang </label>
                    <div class="row">
                        <div class="col-md-4">
                            <label class="fw-bold mb-1"> Panjang </label>
                            <div class="input-group mb-0">
                                <input
                                    type="text"
                                    class="form-control form-format"
                                    placeholder="Panjang"
                                    :value="req?.dimension?.length"
                                    disabled
                                />
                                <span class="input-group-text">Cm</span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label class="fw-bold mb-1"> Lebar </label>
                            <div class="input-group mb-0">
                                <input
                                    type="text"
                                    class="form-control form-format"
                                    placeholder="Lebar"
                                    :value="req?.dimension?.width"
                                    disabled
                                />
                                <span class="input-group-text">Cm</span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label class="fw-bold mb-1"> Tinggi </label>
                            <div class="input-group mb-0">
                                <input
                                    type="text"
                                    class="form-control form-format"
                                    placeholder="Tinggi"
                                    :value="req?.dimension?.height"
                                    disabled
                                />
                                <span class="input-group-text">Cm</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-1">
                        Berat
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <div class="input-group mb-0">
                        <input
                            type="text"
                            class="form-control form-format"
                            placeholder="Berat"
                            :value="req?.weight"
                            disabled
                        />
                        <span class="input-group-text">Kg</span>
                    </div>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-1">
                        Harga Pengiriman
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <div class="input-group mb-0">
                        <span class="input-group-text">Rp</span>
                        <input
                            type="text"
                            class="form-control form-phone"
                            placeholder="Masukkan harga pengiriman"
                            :value="req?.price"
                            disabled
                        />
                    </div>
                </div>
                <div class="col-md-12 mt-3" v-show="isRole(managerRoles)">
                    <label class="fw-bold mb-1">CRM Specialist</label>
                    <multiselect
                        v-model="specialist"
                        :options="specialists"
                        label="name"
                        track-by="id"
                        placeholder="Pilih CRM specialist"
                        class="multi-form-custom"
                        :show-labels="false"
                    >
                    </multiselect>
                </div>
                <div class="col-md-12 mt-3">
                    <label class="fw-bold mb-1">
                        Deskripsi
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <textarea
                        cols="30"
                        rows="2"
                        class="form-control"
                        placeholder="Isi deskripsi"
                        :value="req?.description"
                        disabled
                    ></textarea>
                </div>
            </div>
            <div class="d-flex mt-5">
                <div class="ms-auto">
                    <div class="d-flex">
                        <a
                            href="javascript:void(0)"
                            class="btn btn-outline-yellow px-5 btn-lg me-3"
                        >
                            Batal
                        </a>
                        <button
                            type="button"
                            class="btn btn-primary px-5 btn-lg"
                            @click="modalSuccessConfirmation = true"
                        >
                            Simpan
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- modal -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalIsPrediction"></div>
            <div class="modal-body-chat sm" v-if="modalIsPrediction">
                <div class="mt-4 text-center">
                    <img src="../../assets/info-green.png" width="70" alt="" />
                    <h4 class="mb-0 fw-bold mt-4">
                        Data Ini Diambil Dari Data Prediksi.
                    </h4>
                    <div class="text-gray-dashboard">
                        Anda bisa mengedit data hasil deal order melalui halaman
                        ‘prediksi’.
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mt-4">
                        <a
                            :href="`/prediction/edit/` + $route.params.id"
                            class="btn btn-outline-yellow w-100"
                        >
                            Buka Halaman Prediksi
                        </a>
                    </div>
                    <div class="col-md-6 mt-4">
                        <button
                            type="button"
                            class="btn btn-primary w-100"
                            @click="modalIsPrediction = false"
                        >
                            Ok
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalCreatePrediction"></div>
            <div class="modal-body-chat sm" v-if="modalCreatePrediction">
                <div class="mt-4 text-center">
                    <img src="../../assets/info-yellow.png" width="70" alt="" />
                    <h4 class="mb-0 fw-bold mt-4">
                        Mohon Lengkapi Data Prediksi
                    </h4>
                    <div class="text-gray-dashboard">
                        Sebelum melakukan deal order, mohon lengkapi data
                        prediksi terlebih dahulu.
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mt-4">
                        <a
                            href="/followup/all"
                            class="btn btn-outline-yellow w-100"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-md-6 mt-4">
                        <a
                            :href="`/prediction/edit/` + $route.params.id"
                            class="btn btn-primary w-100"
                        >
                            Buka Halaman Prediksi
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccessConfirmation"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccessConfirmation">
                <div class="mt-4 text-center">
                    <img src="../../assets/info-yellow.png" width="80" alt="" />
                    <h4 class="mb-0 fw-bold mt-4">
                        Data Deal Akan Ditambahkan
                    </h4>
                    <div class="text-gray-dashboard">
                        Pastikan data yang diisi sudah benar
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalSuccessConfirmation = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Batal
                        </a>
                    </div>
                    <div class="col-md-6 mt-4">
                        <button
                            @click="storeData()"
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            :disabled="is_store"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="is_store"
                            ></span>
                            Simpan
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">
                        Data Deal Berhasil Ditambahkan
                    </h4>
                </div>
                <div class="mt-4">
                    <a
                        href="/followup/all"
                        class="btn btn-primary w-100 btn-lg"
                    >
                        Ok
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import Multiselect from "vue-multiselect";

export default {
    components: {
        Multiselect,
    },
    props: ["roles"],
    name: "DealResult",
    data() {
        return {
            req: {
                dimension: {},
            },
            specialist: null,
            specialists: [],
            modalIsPrediction: false,
            managerRoles: ["crm-manager"],
            is_store: false,
            modalSuccess: false,
            modalSuccessConfirmation: false,
            modalCreatePrediction: false,
        };
    },
    created() {
        this.getPredictionDetail();
        this.getSpecialists();
    },
    methods: {
        getPredictionDetail() {
            this.is_loading_data = true;
            var data = {
                followup_id: this.$route.params.id,
            };
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/predictions/detail`, {
                params: data,
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })

                .then((res) => {
                    this.is_loading_data = false;
                    this.req = res.data.data;
                    if (res.data.data == null) {
                        this.modalCreatePrediction = true;
                    } else {
                        this.req.service = res.data.data.service
                            .split("_")
                            .join(" ");
                        this.modalIsPrediction = true;
                    }
                })
                .catch((err) => {
                    this.is_loading_data = false;
                    console.log(err);
                });
        },
        getSpecialists() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/user`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.specialists = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        isRole(value) {
            let result = value.some((el) => this.roles.includes(el));
            return result;
        },
        storeData() {
            this.is_store = true;

            var data = {
                followup_id: this.$route.params.id,
                specialist_id: this.specialist?.id,
            };

            Api.post(`${process.env.VUE_APP_SERVICE_URL}/result/deal`, data, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.is_store = false;
                    this.modalSuccessConfirmation = false;
                    this.modalSuccess = true;
                    console.log(res);
                })
                .catch((err) => {
                    this.is_store = false;
                    if (err.response.data.data == null) {
                        this.$toast.error(err.response.data.message, {
                            position: "top-right",
                            timeout: 2000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: false,
                            icon: true,
                            rtl: false,
                        });
                    } else {
                        this.$toast.error(err.response.data.data.message, {
                            position: "top-right",
                            timeout: 2000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: false,
                            icon: true,
                            rtl: false,
                        });
                    }
                    this.modalSuccessConfirmation = false;
                });
        },
    },
};
</script>

<style scoped></style>
